import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hideLoader, showLoader } from "./loaderSlice";
import api from "../actions/api";
import { showSnackBar } from "./snackSlice";

export const logout = createAction("auth/logout");

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).patch(
        `/user/forgotPassword`,
        data
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        thunkAPI.dispatch(
          showSnackBar({
            message:
              "Reset password link has been sent to your registered email",
            type: "success",
          })
        );
        return res?.data?.data;
      }
      thunkAPI.dispatch(hideLoader());
      thunkAPI.dispatch(
        showSnackBar({ message: "Error encountered!", type: "error" })
      );
      return null;
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      thunkAPI.dispatch(
        showSnackBar({ message: "Error encountered!", type: "error" })
      );
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).patch(
        `/user/change/password`,
        data
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        if (
          res?.data?.status === 406 &&
          res?.data?.message !== "User password changed successfully"
        ) {
          thunkAPI.dispatch(
            showSnackBar({ message: "Password update failed!", type: "error" })
          );
        } else {
          thunkAPI.dispatch(
            showSnackBar({
              message: "Password Updated successfully",
              type: "success",
            })
          );
        }
        return res?.data;
      }
      thunkAPI.dispatch(hideLoader());
      thunkAPI.dispatch(
        showSnackBar({ message: "Password update failed!", type: "error" })
      );
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      thunkAPI.dispatch(
        showSnackBar({ message: "Password update failed!", type: "error" })
      );
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.accessToken = localStorage.setItem("accessToken", action.payload);
    },
    getToken: (state) => {
      state.accessToken = localStorage.getItem("accessToken");
    },
  },
});

export const { setToken, getToken } = authSlice.actions;

export default authSlice.reducer;
