import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import {
  FormHelperText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { groupBy, uniqBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Custom3dotString from "../../../../components/Custom3dotString";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomSwitch from "../../../../components/CustomSwitch";
import CustomTextField from "../../../../components/CustomTextField";
import CustomUpload from "../../../../components/CustomUpload";
import Select from "../../../../components/Select/Select";
import {
  addTaskByPatientId,
  deleteTasksByIds,
  getInpatientById,
  getProductsByClinic,
  getServices,
  updatePatientTaskStatusById,
} from "../../../../redux/reducers/clinicSlice";
import { updatePreventive } from "../../../../redux/reducers/petSlice";
import { getClinicVets } from "../../../../redux/reducers/vetSlice";
import { AppColors } from "../../../../util/AppColors";
import { taskCategory, yesNoBoolList } from "../../../../util/dropList";
import {
  createTimeSlots,
  getDateAndTimeList,
  getDateFormat,
  getTimeFormat,
  isTimeBetween,
} from "../../../../util/function";
import FilesWithCloseButton from "../PatientWorkbook/FilesWithCloseButton";

const tableHeaders = ["tasks", ""];

const planNameExpan = {
  category: "Category",
  task: "Task",
  assignTo: "Assigned to",
  repeatTask: "Repeat Task?",
  noOfTimes: "Repeat Interval (in hrs)",
  startDate: "Start Date",
  endDate: "End Date",
  startTime: "Start Time",
  endTime: "End Time",
  status: "",
};

const planTblHeaders = [
  "category",
  "task",
  "assignTo",
  "repeatTask",
  "noOfTimes",
  "startDate",
  "endDate",
  "startTime",
  "endTime",
  "status",
];

const initPlan = {
  category: null,
  task: null,
  assignTo: null,
  repeatTask: false,
  noOfTimes: null,
  startDate: new Date(),
  endDate: new Date(),
  startTime: new Date(),
  endTime: new Date(),
  serviceId: null,
  productId: null,
};

const initialValues = {
  category: null,
  task: "",
  assignTo: "",
  startDate: new Date(),
  startTime: new Date(),
  repeatTask: false,
  endDate: new Date(),
  endTime: new Date(),
  noOfTimes: "",
  serviceId: null,
  productId: null,
};
const initialErrors = {
  category: false,
  task: false,
  assignTo: false,
  startDate: false,
  startTime: false,
  repeatTask: false,
  endDate: false,
  endTime: false,
  noOfTimes: false,
};
const initialHelpers = {
  category: "",
  task: "",
  assignTo: "",
  startDate: "",
  startTime: "",
  endDate: "",
  endTime: "",
  repeatTask: "",
  noOfTimes: "",
};
const nameExpan = {
  category: "Category",
  task: "Task",
  assignTo: "Assign To",
  startDate: "Start Date",
  startTime: "Start Time",
  repeatTask: "Repeat Task",
  endDate: "End Date",
  endTime: "End Time",
  noOfTimes: "Number of Times",
};

const AdmissionDetails = ({ inpatientId }) => {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("today");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [taskCreModVisble, setTaskCreModVisble] = useState(false);
  const [timeList, setTimeList] = useState([]);
  const [plans, setPlans] = useState([{ ...initPlan, i: 0 }]);
  const patientDetails = useSelector(
    (state) => state?.clinic?.inpatientDetails
  );
  const [vets, setVets] = useState([]);
  const clinicVets = useSelector((state) => state?.vet?.vets);
  const products = useSelector((state) => state?.clinic?.products);
  const petData = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.pet
  );
  const [planError, setPlanError] = useState(false);
  const [modOpen, setModOpen] = useState(false);
  const [taskValues, setTaskValues] = useState(initialValues);
  const [taskErrors, setTaskErrors] = useState(initialErrors);
  const [taskHelpers, setTaskHelpers] = useState(initialHelpers);
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [preventiveList, setPreventiveList] = useState([]);
  const [medicationsWithType, setMedicationsWithType] = useState([]);
  const [supplemetsWithType, setSupplementsWithType] = useState([]);
  const services = useSelector((state) => state?.clinic?.services);
  const [statusUpdateModVsble, setStatusUpdateModVsble] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    getTimeList();
    dispatch(getClinicVets());
    dispatch(getProductsByClinic());
    dispatch(getServices());
  }, []);

  useEffect(() => {
    dispatch(
      getInpatientById({
        inpatientId,
        url: `?date=${moment(new Date(selectedDate)).format("YYYY-MM-DD")}`,
      })
    );
  }, [selectedDate]);

  useEffect(() => {
    const reqVetList = clinicVets
      ?.filter((vl) => {
        if (vl?.userType?.find((ut) => ut?.name === "ROLE_DOCTOR")) {
          return vl;
        }
      })
      ?.map((vet) => {
        return { name: vet?.name, value: vet?.doctorId, id: vet?.doctorId };
      });
    setVets(reqVetList);
  }, [clinicVets]);

  const getTimeList = async () => {
    const reqTimes = await createTimeSlots("00:00", "23:59", 60);
    setTimeList(reqTimes);
  };

  const checkSameDay = () => {
    return (
      new Date(patientDetails?.createDate).getDate() !==
      new Date(selectedDate).getDate()
    );
  };

  const handleDateChange = (value) => {
    const reqDate = new Date(selectedDate);
    reqDate.setDate(
      value === "add" ? reqDate.getDate() + 1 : reqDate.getDate() - 1
    );
    setSelectedDate(reqDate);
    setSelectedType(
      reqDate?.getDate() === new Date().getDate() ? "today" : null
    );
  };

  const onTaskCreateModClose = () => {
    setTaskCreModVisble(false);
    setPlans([{ ...initPlan, i: 0 }]);
    setPlanError(false);
  };

  const handleDeleteTask = (list) => {
    const ids = [];
    list?.filter((li) => ids.push(li?.id));
    dispatch(deleteTasksByIds({ ids })).then((res) => {
      if (res?.payload) {
        dispatch(
          getInpatientById({
            inpatientId,
            url: `?date=${moment(new Date(selectedDate)).format("YYYY-MM-DD")}`,
          })
        );
      }
    });
  };

  const updatePatientTaskById = (itm, status) => {
    dispatch(updatePatientTaskStatusById({ taskId: itm?.id, status })).then(
      (res) => {
        if (res?.payload) {
          if (
            itm?.category === "Diagnostics" ||
            itm?.category === "Preventive"
          ) {
            setSelectedStatus(itm);
            setStatusUpdateModVsble(true);
          }
          dispatch(
            getInpatientById({
              inpatientId,
              url: `?date=${moment(new Date(selectedDate)).format(
                "YYYY-MM-DD"
              )}`,
            })
          );
        }
      }
    );
  };

  const handleChangePlanValue = (name, value, ind, addObj) => {
    const reqList = plans?.map((pl, i) => {
      if (i === ind) {
        const reqObj = { ...pl, [name]: value };
        if (name === "task" && addObj) {
          reqObj.productId = addObj?.productId;
          reqObj.serviceId = addObj?.serviceId;
        }

        return reqObj;
      }
      return pl;
    });
    setPlans(reqList);
  };

  const handelAddNewRow = () => {
    setPlans([...plans, { ...initPlan, i: plans?.length }]);
  };

  const deletePlanByIndex = (ind) => {
    const reqList = plans
      .filter((pl, i) => {
        return i !== ind;
      })
      ?.map((rl, i) => ({ ...rl, i }));
    setPlans(reqList);
  };

  const handleSavePlan = async () => {
    //validation done here
    if (
      plans?.length === 0 ||
      !plans?.length ||
      plans.some((pl) =>
        pl?.category === null ||
        pl?.task === null ||
        pl?.task === "" ||
        pl?.assignTo === null ||
        pl?.repeatTask
          ? pl?.noOfTimes === ""
          : false
      )
    ) {
      return setPlanError(true);
    }

    let tasks = [];
    plans?.filter(async (pl) => {
      if (pl?.repeatTask) {
        const reqDateAndTimes = getDateAndTimeList(
          pl?.startDate,
          pl?.endDate,
          pl?.startTime,
          pl?.endTime,
          pl?.noOfTimes
        );

        reqDateAndTimes?.filter((dt) => {
          const reqTask = {
            category: pl?.category,
            task: pl?.task,
            startDate: dt?.startDate,
            startTime: dt?.startTime,
            repeatTask: pl?.repeatTask,
            doctorId: pl?.assignTo,
            endDate: dt?.endDate,
            endTime: dt?.endTime,
            times: pl?.noOfTimes,
          };
          if (pl?.category !== "Others") {
            if (pl?.serviceId) reqTask.serviceId = pl?.serviceId;
            if (pl?.productId) reqTask.productId = pl?.productId;
          }
          tasks = [...tasks, reqTask];
        });
      } else {
        const reqTask = {
          category: pl?.category,
          task: pl?.task,
          startDate: getDateFormat(pl?.startDate),
          startTime: getTimeFormat(pl?.startTime),
          repeatTask: pl?.repeatTask,
          doctorId: pl?.assignTo,
        };
        if (pl?.category !== "Others") {
          if (pl?.serviceId) reqTask.serviceId = pl?.serviceId;
          if (pl?.productId) reqTask.productId = pl?.productId;
        }
        tasks = [...tasks, reqTask];
      }
    });

    if (tasks?.length === 0) return;
    dispatch(
      addTaskByPatientId({ patientId: inpatientId, data: { tasks } })
    ).then((res) => {
      if (res?.payload) {
        dispatch(
          getInpatientById({
            inpatientId,
            url: `?date=${moment(new Date(selectedDate)).format("YYYY-MM-DD")}`,
          })
        );
        onTaskCreateModClose();
      }
    });
  };

  const handleModClose = () => {
    setModOpen(false);
    setTaskValues(initialValues);
    setTaskErrors(initialErrors);
    setTaskHelpers(initialHelpers);
  };

  const handleChange = (name, value, addObj) => {
    const reqObj = { ...taskValues, [name]: value };
    if (name === "task" && addObj) {
      reqObj.productId = addObj?.productId;
      reqObj.serviceId = addObj?.serviceId;
    }
    setTaskValues(reqObj);
    setTaskErrors({
      ...taskErrors,
      [name]: value !== "" && value !== null ? false : true,
    });
    setTaskHelpers({
      ...taskHelpers,
      [name]:
        value !== "" && value !== null
          ? ""
          : `${nameExpan?.[name]} is required`,
    });
  };

  const handleValidation = () => {
    let errs = {};
    let helps = {};
    Object.keys(taskValues).forEach((det) => {
      if (
        taskValues?.[det] === null ||
        taskValues?.[det] === "" ||
        taskValues?.[det]?.length === 0 ||
        !taskValues?.[det]
      ) {
        errs = {
          ...errs,
          [det]: true,
        };
        helps = {
          ...helps,
          [det]: `${nameExpan?.[det]} is required field`,
        };
      }
      delete errs.repeatTask;
      delete errs.serviceId;
      delete errs.productId;

      if (!taskValues?.repeatTask) {
        delete errs.noOfTimes;
        helps.noOfTimes = "";
      }
      setTaskErrors({ ...taskErrors, ...errs });
      setTaskHelpers({ ...taskHelpers, ...helps });
    });
    return Object.keys(errs).length > 0 ? true : false;
  };

  const onUploadFile = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFiles([
        ...files,
        {
          file: e.target.files[0],
          previewUrl: reader.result,
        },
      ]);
    };
    reader.readAsDataURL(file);
  };

  const handleSaveTask = async () => {
    const isNotValid = handleValidation();
    if (isNotValid) return;
    let tasks = [];
    if (taskValues?.repeatTask) {
      const reqDateAndTimes = getDateAndTimeList(
        taskValues?.startDate,
        taskValues?.endDate,
        taskValues?.startTime,
        taskValues?.endTime,
        taskValues?.noOfTimes
      );

      reqDateAndTimes?.filter((dt) => {
        const reqTask = {
          category: taskValues?.category,
          task: taskValues?.task,
          startDate: dt?.startDate,
          startTime: dt?.startTime,
          repeatTask: taskValues?.repeatTask,
          doctorId: taskValues?.assignTo,
          endDate: dt?.startDate,
          endTime: dt?.endTime,
          times: taskValues?.noOfTimes,
        };
        if (taskValues?.category !== "Others") {
          if (taskValues?.serviceId) reqTask.serviceId = taskValues?.serviceId;
          if (taskValues?.productId) reqTask.productId = taskValues?.productId;
        }
        tasks = [...tasks, reqTask];
      });
    } else {
      const reqTask = {
        category: taskValues?.category,
        task: taskValues?.task,
        startDate: getDateFormat(taskValues?.startDate),
        startTime: getTimeFormat(taskValues?.startTime),
        repeatTask: taskValues?.repeatTask,
        doctorId: taskValues?.assignTo,
      };
      if (taskValues?.category !== "Others") {
        if (taskValues?.serviceId) reqTask.serviceId = taskValues?.serviceId;
        if (taskValues?.productId) reqTask.productId = taskValues?.productId;
      }
      tasks = [reqTask];
    }
    if (tasks?.length === 0) return;

    dispatch(
      addTaskByPatientId({ patientId: inpatientId, data: { tasks } })
    ).then((res) => {
      if (res?.payload) {
        dispatch(
          getInpatientById({
            inpatientId,
            url: `?date=${moment(new Date(selectedDate)).format("YYYY-MM-DD")}`,
          })
        );
        handleModClose();
      }
    });
  };

  const handleAddTask = (value) => {
    setTaskValues({ ...taskValues, category: value });
    setModOpen(true);
  };

  const handleSave = (status) => {
    let price = 0,
      tax = 0;
    if (selectedStatus?.category === "Diagnostics") {
      const res = diagnosticList?.find(
        (dl) => dl?.value === selectedStatus?.task
      );
      if (res) {
        price = res?.servicePrice ?? 0;
        tax = res?.tax ?? 0;
      }
    }
    if (selectedStatus?.category === "Preventive") {
      const res = preventiveList?.find(
        (pl) => pl?.value === selectedStatus?.task
      );
      if (res) {
        price = res?.sellPrice ?? 0;
        tax = res?.tax ?? 0;
      }
    }
    if (selectedStatus?.category === "Medication") {
      const res = medicationsWithType?.find(
        (ml) => ml?.value === selectedStatus?.task
      );
      if (res) {
        price = res?.sellPrice ?? 0;
        tax = res?.tax ?? 0;
      }
    }

    const form = new FormData();
    form.append("type", selectedStatus?.category);
    form.append("name", selectedStatus?.task);
    form.append("price", price);
    form.append("tax", tax);
    form.append("date", selectedStatus?.startDate);
    form.append("remark", remarks);
    form.append("status", status);
    form.append("inpatient", true);
    if (files?.length > 0) {
      files?.forEach((fle) => {
        form.append("files", fle?.file ?? fle?.previewUrl);
      });
    }

    if (selectedStatus?.category === "Preventive") {
      dispatch(updatePreventive({ preventId: selectedStatus?.id, form })).then(
        (res) => {
          onUpdateModClose();
        }
      );
    }
  };

  const handleImageRemove = (ind) => {
    const reqFiles = files?.filter((fl, i) => i !== ind);
    setFiles(reqFiles);
  };

  const onUpdateModClose = () => {
    setStatusUpdateModVsble(false);
    setFiles(null);
    setRemarks("");
  };

  const getTableRowByName = (name) => {
    return (
      <TableRow tabIndex={-1}>
        <TableCell
          component="th"
          className="tbl-brdr"
          style={{
            textAlign: "left",
            position: "sticky",
            background: AppColors.white,
            left: 0,
            zIndex: 100,
          }}
        >
          <div className="flex-row-ali-cen" style={{ width: 200 }}>
            <Typography className="font-bold fs16 blue-color">
              {name}
            </Typography>
            <div className="flex1-end">
              <img
                src={require("../../../../assets/images/png/plus.png")}
                alt=""
                className="img-wh15 cursor"
                onClick={() => handleAddTask(name)}
              />
            </div>
          </div>
        </TableCell>
        <div className="flex-row-ali-cen">
          {timeList?.length > 0 &&
            timeList?.map((tm) => (
              <TableCell
                component="th"
                className="tbl-brdr"
                style={{ height: 58, width: 150 }}
              >
                {null}
              </TableCell>
            ))}
        </div>
      </TableRow>
    );
  };

  const getTableRows = (type) => {
    const reqFilterList = patientDetails?.tasks ?? [];
    let list = reqFilterList?.filter((tsk) => tsk?.category === type);
    if (type === "Preventive") {
      list = reqFilterList?.filter(
        (tsk) =>
          tsk?.category === "Deworming" ||
          tsk?.category === "Flea & Tick Treatment" ||
          tsk?.category === "Vaccines"
      );
    }

    const groupByList = groupBy(list, "task");
    const uniqTasks = uniqBy(list, "task");

    return uniqTasks?.map((li, ind) => (
      <TableRow tabIndex={-1} key={ind}>
        <TableCell
          component="th"
          className="tbl-brdr"
          style={{
            textAlign: "left",
            position: "sticky",
            background: AppColors.white,
            left: 0,
            zIndex: 100,
          }}
        >
          <div className="flex-row-ali-cen" style={{ width: 200 }}>
            <Custom3dotString
              str={li?.task}
              className="txt-regular fs14 black"
              length={20}
            />

            {/* <div className="flex1-end">
              <img
                src={require("../../../../assets/images/png/del.png")}
                alt=""
                className="img-wh15 cursor"
                onClick={() => handleDeleteTask(groupByList?.[li?.task])}
              />
            </div> */}
          </div>
        </TableCell>
        <div className="flex-row-ali-cen">
          {timeList?.length > 0 &&
            timeList?.map((tm) => {
              const timeMatch = groupByList?.[li?.task]?.filter((li) =>
                isTimeBetween(li?.startTime, tm)
              );

              return (
                <TableCell
                  component="th"
                  className="tbl-brdr flex-center"
                  style={{ height: 60, width: 150 }}
                >
                  <div className="flex-column">
                    {timeMatch?.length > 0
                      ? timeMatch?.map((tmm) => {
                          const [hour, min] = tmm?.startTime?.split(":");
                          const reqDate = new Date(
                            new Date(selectedDate).setHours(hour, min)
                          );

                          const isTimeExceed =
                            new Date().getTime() > reqDate.getTime();

                          return (
                            <div className="flex-row-ali-cen">
                              <CustomSwitch
                                value={tmm?.status}
                                onChange={(e) =>
                                  updatePatientTaskById(tmm, !tmm?.status)
                                }
                                grayRedGreen
                                red={isTimeExceed}
                                disabled={tmm?.status}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                </TableCell>
              );
            })}
        </div>
      </TableRow>
    ));
  };

  const getTypeBasedList = (typ) => {
    if (typ === "Others") return [];
    if (typ === "Diagnostics") {
      return services?.data
        ?.filter((ser) => ser?.category === typ)
        ?.map((dl) => ({
          ...dl,
          name: dl?.serviceName,
          value: dl?.serviceName,
        }));
    }
    if (typ === "Medication") {
      return products?.data
        ?.filter(
          (prd) =>
            prd?.category === "Nutrition and Supplements" ||
            prd?.category === typ
        )
        ?.map((pd) => ({
          ...pd,
          value: pd?.name,
        }));
    }
    return products?.data
      ?.filter((prd) => prd?.category === typ)
      ?.map((pd) => ({
        ...pd,
        value: pd?.name,
      }));
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="flex-row-ali-cen">
            <div className="w85Per inpat-gray-back">
              <div className="flex-row-ali-cen">
                <div className="w75Per">
                  <div className="flex-column">
                    <div className="flex-row-ali-cen">
                      <Typography className="card-black fs12 txt-semi-bold">
                        Admission Date:{" "}
                        {moment(patientDetails?.createDate).format(
                          "DD MMM YYYY"
                        )}
                      </Typography>
                      <Typography className="red6 fs12 txt-semi-bold ml60">
                        Kennel Number: {patientDetails?.kennelNumber}
                      </Typography>
                    </div>
                    <Typography className="txt-regular fs12 black mt5">
                      {patientDetails?.remark}
                    </Typography>
                  </div>
                </div>
                <div className="w2Per">
                  <div className="flex-center">
                    <div className="ver-bar2 h50" />
                  </div>
                </div>
                <div className="w20Per">
                  <div className="flex-column flex1-end">
                    <Typography className="red8 header fs12">
                      Balance Due: 4005 Rs
                    </Typography>
                    <Typography className="red9 header fs12 mt5">
                      Outstanding Tasks:{" "}
                      {patientDetails?.tasks?.length > 0
                        ? patientDetails?.tasks?.reduce((acc, obj) => {
                            return obj.status === false ? acc + 1 : acc;
                          }, 0)
                        : "0"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="w15Per">
              <div className="flex1-end">
                <div>
                  <CustomButton
                    text="Create Plan"
                    smallBtn
                    onClick={() => setTaskCreModVisble(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt20">
          <div className="flex-row">
            <div className="flex-start">
              <div className="flex-center">
                <Typography
                  className={`font-bold fs14 cursor day-type-box ${
                    selectedType === "today" ? "black" : "gray2"
                  }`}
                  onClick={() => {
                    setSelectedType("today");
                    setSelectedDate(new Date());
                  }}
                >
                  Today
                </Typography>
              </div>
            </div>
            <div className="flex1-center">
              <div className="flex-row">
                <div className="flex-center">
                  <NavigateBeforeOutlinedIcon
                    className={`calendar-arrow ${
                      checkSameDay() ? "cursor" : "no-cursor"
                    }`}
                    onClick={() => handleDateChange("minus")}
                  />
                  <Typography className="text-bold mh20">
                    {moment(new Date(selectedDate)).format("DD MMM YYYY")}
                  </Typography>
                  <NavigateNextOutlinedIcon
                    className={`calendar-arrow cursor`}
                    onClick={() => handleDateChange("add")}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="mt20 pb100"
        >
          <TableContainer sx={{ maxHeight: 330 }}>
            <Table
              sx={{ minWidth: 200 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {tableHeaders?.map((hl, i) => (
                    <>
                      <TableCell
                        key={i}
                        style={
                          i === 0
                            ? {
                                backgroundColor: AppColors.tableGrey,
                                color: AppColors.white,
                                textAlign: "center",
                                position: "sticky",
                                left: 0,
                                zIndex: 105,
                              }
                            : {
                                backgroundColor: AppColors.tableGrey,
                                color: AppColors.white,
                                textAlign: "center",
                              }
                        }
                        sortDirection={false}
                        className={`table-header-text capitalize `}
                      >
                        {hl === "tasks" ? (
                          <div className="flex-center" style={{ width: 200 }}>
                            {hl}
                          </div>
                        ) : (
                          <div className="flex-row-ali-cen mhMin16">
                            {timeList?.length > 0 &&
                              timeList?.map((tm) => (
                                <div style={{ width: 150 }}>
                                  <Typography className="header fs12">
                                    {tm}
                                  </Typography>
                                </div>
                              ))}
                          </div>
                        )}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getTableRowByName("Diagnostics")}
                {getTableRows("Diagnostics")}
                {getTableRowByName("Preventive")}
                {getTableRows("Preventive")}
                {getTableRowByName("Medication")}
                {getTableRows("Medication")}
                {getTableRowByName("Others")}
                {getTableRows("Others")}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <CustomModal
        open={taskCreModVisble}
        onClose={onTaskCreateModClose}
        header="Create Plan"
        modal
        modalWidth={95}
      >
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            sx={{ minWidth: 200 }}
            aria-labelledby="tableTitle"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {planTblHeaders?.map((hl, i) => (
                  <TableCell
                    key={i}
                    style={{
                      backgroundColor: AppColors.tableGrey,
                      color: AppColors.white,
                      textAlign: "left",
                    }}
                    sortDirection={false}
                    className={`table-header-text ${
                      hl === "task" || hl === "assignTo"
                        ? "w15Per"
                        : hl === "status" || hl === "noOfTimes"
                        ? "w5Per"
                        : "w10Per"
                    }`}
                  >
                    {planNameExpan?.[hl]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {plans?.length > 0 &&
                plans?.map((pl, ind) => (
                  <TableRow tabIndex={-1} key={ind + pl?.i}>
                    {planTblHeaders?.map((th, index) => (
                      <TableCell
                        key={index + th + "tr"}
                        component="th"
                        className={
                          th === "task" || th === "assignTo"
                            ? "w15Per"
                            : th === "status" || th === "noOfTimes"
                            ? "w5Per"
                            : "w10Per"
                        }
                      >
                        {th === "category" ? (
                          <Select
                            list={taskCategory}
                            value={pl?.category}
                            select
                            handleChange={(e) => {
                              handleChangePlanValue(
                                "category",
                                e?.target?.value,
                                ind
                              );
                            }}
                          />
                        ) : th === "task" ? (
                          pl?.category === "Others" ? (
                            <CustomTextField
                              fullWidth
                              handleChange={(e) =>
                                handleChangePlanValue(
                                  "task",
                                  e?.target?.value,
                                  ind
                                )
                              }
                              value={pl?.task}
                            />
                          ) : (
                            <Select
                              list={
                                getTypeBasedList(pl?.category)
                                // pl?.category === "Diagnostics"
                                //   ? diagnosticList
                                //   : pl?.category === "Preventive"
                                //   ? preventiveList
                                //   : pl?.category === "Medication"
                                //   ? [
                                //       ...medicationsWithType,
                                //       ...supplemetsWithType,
                                //     ]
                                //   : []
                              }
                              value={pl?.task}
                              addNewSelect
                              handleChange={(e) => {
                                handleChangePlanValue(
                                  "task",
                                  e?.value ?? e,
                                  ind,
                                  {
                                    productId: e?.productId ?? null,
                                    serviceId: e?.serviceId ?? null,
                                  }
                                );
                              }}
                            />
                          )
                        ) : th === "assignTo" ? (
                          <Select
                            list={vets}
                            value={pl?.assignTo}
                            select
                            handleChange={(e) => {
                              handleChangePlanValue(
                                "assignTo",
                                e?.target?.value,
                                ind
                              );
                            }}
                          />
                        ) : th === "repeatTask" ? (
                          <Select
                            list={yesNoBoolList}
                            value={pl?.repeatTask}
                            select
                            handleChange={(e) => {
                              handleChangePlanValue(
                                "repeatTask",
                                e?.target?.value,
                                ind
                              );
                            }}
                          />
                        ) : th === "noOfTimes" ? (
                          <CustomTextField
                            fullWidth
                            handleChange={(e) =>
                              handleChangePlanValue(
                                "noOfTimes",
                                e?.target?.value,
                                ind
                              )
                            }
                            value={pl?.noOfTimes}
                            disabled={!pl?.repeatTask}
                          />
                        ) : th === "startDate" ? (
                          <CustomTextField
                            fullWidth
                            value={pl?.startDate}
                            handleChange={(e) => {
                              handleChangePlanValue(
                                "startDate",
                                e?.target?.value,
                                ind
                              );
                            }}
                            mobileDate
                          />
                        ) : th === "endDate" ? (
                          <CustomTextField
                            fullWidth
                            handleChange={(e) => {
                              handleChangePlanValue(
                                "endDate",
                                e?.target?.value,
                                ind
                              );
                            }}
                            value={pl?.endDate}
                            mobileDate
                            disabled={!pl?.repeatTask}
                          />
                        ) : th === "startTime" ? (
                          <CustomTextField
                            fullWidth
                            handleChange={(e) =>
                              handleChangePlanValue(
                                "startTime",
                                e?.target?.value,
                                ind
                              )
                            }
                            value={pl?.startTime}
                            mobileTime
                            is12HourFomat={false}
                          />
                        ) : th === "endTime" ? (
                          <CustomTextField
                            fullWidth
                            handleChange={(e) =>
                              handleChangePlanValue(
                                "endTime",
                                e?.target?.value,
                                ind
                              )
                            }
                            value={pl?.endTime}
                            mobileTime
                            is12HourFomat={false}
                            disabled={!pl?.repeatTask}
                          />
                        ) : (
                          <div className="flex-row flex-center">
                            <img
                              src={require("../../../../assets/images/png/delete.png")}
                              alt=""
                              className="imghw30 ml10 cursor"
                              onClick={() => deletePlanByIndex(ind)}
                            />
                          </div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div className="flex-center">
            <FormHelperText error>
              {planError || plans?.length === 0 || !plans?.length
                ? "Please add at least 1 record"
                : plans?.some((pl) =>
                    pl?.category === null ||
                    pl?.task === null ||
                    pl?.task === "" ||
                    pl?.assignTo === null ||
                    pl?.repeatTask
                      ? pl?.noOfTimes === ""
                      : false
                  )
                ? "Please add Valid records"
                : ""}
            </FormHelperText>
          </div>
          <div className="flex-row-ali-cen mt10">
            <div className="flex-start">
              <div>
                <CustomButton
                  text="Add New"
                  smallBtn
                  onClick={handelAddNewRow}
                />
              </div>
            </div>
            <div className="flex1-end">
              <div>
                <CustomButton text="Save" smallBtn onClick={handleSavePlan} />
              </div>
            </div>
          </div>
        </TableContainer>
      </CustomModal>
      <CustomModal
        open={modOpen}
        onClose={handleModClose}
        header="Add Task"
        rightModal
        modalWidth={30}
      >
        <div className="p20 scroll-80vh">
          <div className="inpat-border">
            <div className="flex-row-ali-cen">
              <div className="w75Per">
                <div className="flex-row-ali-cen">
                  {patientDetails?.petImage ? (
                    <img
                      src={patientDetails?.petImage}
                      alt=""
                      className="pet-card-img"
                    />
                  ) : (
                    <div className="flex-center tbl-emp-img2">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {patientDetails?.petName?.[0]}
                      </Typography>
                    </div>
                  )}

                  <div className="ml10">
                    <div className="flex-column">
                      <div className="flex-row-ali-cen">
                        <Typography className="txt-semi-bold card-black fs14">
                          {patientDetails?.petName}
                        </Typography>
                        <Typography
                          className={`capitalize header fs14 ml5 ${
                            patientDetails?.gender === "male"
                              ? "card-blue-color"
                              : "card-rose-color"
                          }`}
                        >
                          ({patientDetails?.gender})
                        </Typography>
                      </div>
                      <Typography className="card-black1 fs12 txt-regular">
                        {patientDetails?.breed}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w2Per">
                <div className="ver-bar h50" />
              </div>
              <div className="flex1-end">
                <div className="flex1-end">
                  <div className="flex-column">
                    <div className="flex1-end">
                      <Typography className="txt-semi-bold fs12 black7">
                        {moment(patientDetails?.dob).fromNow()}
                      </Typography>
                    </div>
                    <div className="flex1-end">
                      <div className="flex-row-ali-cen mt5">
                        <img
                          src={require("../../../../assets/images/png/weight.png")}
                          alt=""
                          className="img-wh15"
                        />
                        <Typography className="txt-semi-bold fs12 card-black ml5">
                          {patientDetails?.weight} kg
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row-ali-cen mt10">
              <div className="flex-row-ali-cen">
                <AccountCircleOutlinedIcon sx={{ width: 20, height: 20 }} />
                <Typography className="fs12 txt-semi-bold card-black ml5">
                  {patientDetails?.userName ?? petData?.userName}
                </Typography>
              </div>
              <div className="flex1-end">
                <Typography className="card-black1 fs12 txt-semi-bold">
                  Dr. {patientDetails?.doctorName}
                </Typography>
              </div>
            </div>
          </div>

          <div className="inpat-blue-back mt20">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-mont fs14">Category</div>
                <Select
                  list={taskCategory}
                  value={taskValues?.category}
                  handleChange={(e) =>
                    handleChange("category", e?.target?.value)
                  }
                  select
                  error={taskErrors?.category}
                  helperText={taskHelpers?.category}
                  backgroundColor={AppColors.white}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-mont fs14">Task</div>
                {taskValues?.category === "Others" ? (
                  <CustomTextField
                    fullWidth
                    handleChange={(e) => handleChange("task", e?.target?.value)}
                    value={taskValues?.task}
                    helperText={taskHelpers?.task}
                    error={taskErrors?.task}
                  />
                ) : (
                  <Select
                    list={
                      getTypeBasedList(taskValues?.category)
                      // taskValues?.category === "Diagnostics"
                      //   ? diagnosticList
                      //   : taskValues?.category === "Preventive"
                      //   ? preventiveList
                      //   : taskValues?.category === "Medication"
                      //   ? [...medicationsWithType, ...supplemetsWithType]
                      //   : []
                    }
                    value={taskValues?.task}
                    error={taskErrors?.task}
                    helperText={taskHelpers?.task}
                    addNewSelect
                    handleChange={(e) => {
                      handleChange("task", e?.value ?? e, {
                        productId: e?.productId ?? null,
                        serviceId: e?.serviceId ?? null,
                      });
                    }}
                    backgroundColor={AppColors.white}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-mont fs14 mt10">Assign to</div>
              <Select
                list={vets}
                value={taskValues?.assignTo}
                handleChange={(e) => handleChange("assignTo", e?.target?.value)}
                select
                error={taskErrors?.assignTo}
                helperText={taskHelpers?.assignTo}
                backgroundColor={AppColors.white}
              />
            </Grid>

            <div className="flex-row-ali-cen mt10">
              <div className="w50Per pr10">
                <div className="txt-mont fs14">Start Date</div>
                <CustomTextField
                  fullWidth
                  handleChange={(e) => {
                    handleChange("startDate", e?.target?.value);
                  }}
                  value={taskValues?.startDate}
                  helperText={taskHelpers?.startDate}
                  error={taskErrors?.startDate}
                  mobileDate
                  placeholder={nameExpan?.startDate}
                />
              </div>
              <div className="w50Per pl10">
                <div className="txt-mont fs14">Start Time</div>
                <CustomTextField
                  fullWidth
                  handleChange={(e) =>
                    handleChange("startTime", e?.target?.value)
                  }
                  value={taskValues?.startTime}
                  helperText={taskHelpers?.startTime}
                  error={taskErrors?.startTime}
                  mobileTime
                  is12HourFomat={false}
                  placeholder={nameExpan?.startTime}
                  backgroundColor={AppColors.white}
                />
              </div>
            </div>
            <div className="flex-row-ali-cen mt10">
              <div className="w50Per pr10">
                <div className="flex-row-ali-cen mt10">
                  <Typography className="txt-semi-bold fs12 mark-balck">
                    Repeat Task?
                  </Typography>
                  <div className="ml15 mt5">
                    <CustomSwitch
                      value={taskValues?.repeatTask}
                      onChange={(e) =>
                        handleChange("repeatTask", !taskValues?.repeatTask)
                      }
                      greenToGray
                    />
                  </div>
                </div>
              </div>
              {taskValues?.repeatTask && (
                <div className="w50Per pl10">
                  <div className="txt-mont fs14">Repeat Interval (in hrs)</div>
                  <CustomTextField
                    fullWidth
                    handleChange={(e) =>
                      handleChange("noOfTimes", e?.target?.value)
                    }
                    value={taskValues?.noOfTimes}
                    helperText={taskHelpers?.noOfTimes}
                    error={taskErrors?.noOfTimes}
                  />
                </div>
              )}
            </div>

            {taskValues?.repeatTask ? (
              <>
                <div className="flex-row-ali-cen mt10">
                  <div className="w50Per pr10">
                    <div className="txt-mont fs14">End Date</div>
                    <CustomTextField
                      fullWidth
                      handleChange={(e) => {
                        handleChange("endDate", e?.target?.value);
                      }}
                      value={taskValues?.endDate}
                      helperText={taskHelpers?.endDate}
                      error={taskErrors?.endDate}
                      mobileDate
                      placeholder={nameExpan?.endDate}
                    />
                  </div>
                  <div className="w50Per pl10">
                    <div className="txt-mont fs14">End Time</div>
                    <CustomTextField
                      fullWidth
                      handleChange={(e) =>
                        handleChange("endTime", e?.target?.value)
                      }
                      value={taskValues?.endTime}
                      helperText={taskHelpers?.endTime}
                      error={taskErrors?.endTime}
                      mobileTime
                      is12HourFomat={false}
                      placeholder={nameExpan?.endTime}
                      backgroundColor={AppColors.white}
                    />
                  </div>
                </div>
                <div className="flex-row-ali-cen mt10"></div>
              </>
            ) : null}
            <div className="flex-row-ali-cen mt20">
              <div className="flex1-end">
                <div>
                  <CustomButton smallBtn text="Save" onClick={handleSaveTask} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={statusUpdateModVsble}
        onClose={onUpdateModClose}
        header={"Update"}
        rightModal
        modalWidth={30}
      >
        <div className="scroll-80vh">
          <Grid container spacing={2} className="ph20 mt10">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label="Select Type"
                fullWidth
                value={selectedStatus?.category}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label="Name"
                fullWidth
                value={selectedStatus?.task}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label="Due Date"
                fullWidth
                value={selectedStatus?.startDate}
                mobileDate
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label="Remarks"
                placeholder="Remarks"
                name="remarks"
                fullWidth
                handleChange={(e) => setRemarks(e?.target?.value)}
                value={remarks}
                multiline
              />
            </Grid>

            {files?.length > 0 ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FilesWithCloseButton
                  files={files}
                  onClickFile={(i, file) => handleImageRemove(i)}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomUpload onUploadFile={onUploadFile} multipleNew />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex1-center">
                <div>
                  <CustomButton
                    text="Mark as complete"
                    onClick={() => handleSave("Completed")}
                    tabSelectdBtn
                    noBgbtn
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
};

export default AdmissionDetails;
