import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
import { uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import { getClinicHolidays } from "../../../redux/reducers/clinicSlice";
import { getVetHolidaysById } from "../../../redux/reducers/doctorSlice";
import {
  getSlotTime,
  petAppointment,
  petAppointmentImmediate,
} from "../../../redux/reducers/petSlice";
import { AppColors } from "../../../util/AppColors";
import {
  serviceType,
  typeAppointemnt,
  typeList,
  typeListNew,
} from "../../../util/dropList";
import { getAllDatesFromArray } from "../../../util/function";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
const initialErrors = {
  typeOfAppointment: false,
  serviceType: false,
  consultationType: false,
  reason: false,
  vetName: false,
  appointmentOption: false,
};
const initialHelpers = {
  typeOfAppointment: "",
  serviceType: "",
  consultationType: "",
  reason: "",
  vetName: "",
  appointmentOption: "",
};

const initialValues = {
  typeOfAppointment: "Phone",
  serviceType: "Consultation",
  consultationType: "Virtual",
  reason: "",
  vetName: "",
  date: new Date(),
  appointmentOption: null,
};

const ClinicPetBookAppointment = ({
  modalVisible,
  setModalBookVisible,
  selectPet,
}) => {
  const clinicVets = useSelector((state) => state?.vet?.vets);
  const slotList = useSelector((state) => state?.pet?.slotList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modOpen, setModOpen] = useState(false);
  const [petValues, setPetValues] = useState(initialValues);
  const [petErrors, setPetErrors] = useState(initialErrors);
  const [petHelpers, setPetHelpers] = useState(initialHelpers);
  const [vets, setVets] = useState([]);
  const [selectTime, setSelectTime] = useState();
  const [selectTimeId, setSelectTimeId] = useState("");
  const [selectTimeIdError, setSelectTimeIdError] = useState(false);
  const activeSlotList = slotList?.filter((status) => status?.status === "N");
  const holidays = useSelector((state) => state?.doctor?.holidays);
  const [holidayDates, setHolidayDates] = useState([]);
  const clinicHolidays = useSelector((state) => state?.clinic?.holidays);

  useEffect(() => {
    dispatch(getClinicHolidays());
  }, []);

  useEffect(() => {
    setModOpen(modalVisible);
  }, [modalVisible]);

  useEffect(() => {
    const reqVetList = clinicVets
      ?.filter((vl) => {
        if (vl?.userType?.find((ut) => ut?.name === "ROLE_DOCTOR")) {
          return vl;
        }
      })
      ?.map((vet) => {
        return { name: vet?.name, value: vet?.doctorId, id: vet?.doctorId };
      });
    setVets(reqVetList);
  }, [clinicVets]);

  useEffect(() => {
    getHolidayDateList();
  }, [holidays, clinicHolidays]);

  useEffect(() => {
    if (
      !petValues?.vetName ||
      !petValues?.date ||
      !petValues?.appointmentOption
    ) {
      return;
    }
    if (petValues?.appointmentOption === "Later") {
      dispatch(getVetHolidaysById(petValues?.vetName));
      const data = {
        doctorId: petValues?.vetName,
        contType: petValues?.consultationType,
        date: moment(new Date(petValues?.date)).format("YYYY-MM-DD"),
      };
      dispatch(getSlotTime(data));
    }
  }, [
    petValues?.vetName,
    petValues?.date,
    petValues?.appointmentOption,
    petValues?.consultationType,
  ]);

  const getHolidayDateList = async () => {
    if (
      (holidays?.length === 0 || !holidays) &&
      (clinicHolidays?.length === 0 || !clinicHolidays)
    ) {
      return setHolidayDates([]);
    }
    const vetDates = (await getAllDatesFromArray(holidays)) ?? [];

    const cliHolidays =
      clinicHolidays?.map((hl) => {
        return hl?.date;
      }) ?? [];
    const mergedArray = vetDates?.concat(cliHolidays);
    setHolidayDates(uniq(mergedArray));
  };

  const handleSelectTime = (time) => {
    setSelectTime(time?.time);
    setSelectTimeId(time?.slotTimeId);
    setSelectTimeIdError(false);
  };

  const handleReset = () => {
    setPetValues(initialValues);
    setPetErrors(initialErrors);
    setPetHelpers(initialHelpers);
    setSelectTime(null);
    setSelectTimeId(null);
    setSelectTimeIdError(false);
  };

  const modelOpen = () => {
    handleReset();
    setModOpen(false);
    setModalBookVisible(false);
    setSelectTime(null);
    setSelectTimeId(null);
  };

  const handleSelect = (e, key) => {
    const { value } = e?.target;
    const reqObj = { ...petValues, [key]: value };
    if (key === "typeOfAppointment" && value === "Walk-in") {
      reqObj.consultationType = "Physical";
    }
    setPetErrors({
      ...petErrors,
      [key]: value?.toString()?.length > 0 ? false : true,
    });
    setPetHelpers({
      ...petHelpers,
      [key]: value?.toString()?.length > 0 ? "" : "Required Field",
    });
    setPetValues(reqObj);
  };

  const handleValidation = () => {
    const errorList = [];
    const woErrorList = [];
    Object.keys(petValues).forEach(function (key, index) {
      if (petValues?.appointmentOption !== "Immediate" || key !== "date") {
        if (
          petValues?.[key]?.length === 0 ||
          petValues?.[key] === "" ||
          petValues?.[key] === null
        ) {
          return errorList?.push(key);
        }
        woErrorList.push(key);
      }
    });
    let errorObj = {};
    let errHelperObj = {};
    let correctObj = {};
    let helperObj = {};
    //set Error
    if (errorList?.length > 0) {
      errorList?.forEach((key) => {
        errorObj = { ...errorObj, [key]: true };
        errHelperObj = { ...errHelperObj, [key]: `Required Field` };
      });
    }
    //Remove Error
    if (woErrorList?.length > 0) {
      woErrorList?.forEach((key) => {
        correctObj = { ...correctObj, [key]: false };
        helperObj = { ...helperObj, [key]: "" };
      });
    }
    setPetErrors({ ...petErrors, ...correctObj, ...errorObj });
    setPetHelpers({ ...petHelpers, ...helperObj, ...errHelperObj });
    return { errorList, woErrorList };
  };

  const handleSubmit = () => {
    const validation = handleValidation();
    if (validation?.errorList?.length > 0) return;
    if (petValues?.appointmentOption === "Immediate") {
      const data = {
        bookingType: petValues?.typeOfAppointment,
        consultationMode: petValues?.consultationType,
        serviceType: petValues?.serviceType,
        reason: petValues?.reason,
        doctorId: petValues?.vetName,
        slotId: activeSlotList?.[0]?.slotdayId,
      };
      const petId = selectPet?.petId;
      const metaData = { petId, data };
      dispatch(showLoader());
      dispatch(petAppointmentImmediate(metaData)).then((res) => {
        if (res?.payload?.status === 200) {
          modelOpen();
          navigate("/dashboard");
        }
      });
      dispatch(hideLoader());
    } else {
      if (
        selectTimeId === null ||
        selectTimeId === undefined ||
        selectTimeId === ""
      ) {
        setSelectTimeIdError(true);
      } else {
        const data = {
          bookingType: petValues?.typeOfAppointment,
          consultationMode: petValues?.consultationType,
          serviceType: petValues?.serviceType,
          reason: petValues?.reason,
          doctorId: petValues?.vetName,
          appointmentDate: moment(new Date(petValues?.date)).format(
            "YYYY-MM-DD"
          ),
          appoimentTime: selectTime,
          timeId: selectTimeId,
          slotId: activeSlotList?.[0]?.slotdayId,
        };
        const petId = selectPet?.petId;
        const metaData = { petId, data };
        dispatch(showLoader());
        dispatch(petAppointment(metaData)).then((res) => {
          if (res?.payload?.status === 200) {
            modelOpen();
            navigate("/dashboard");
          }
          dispatch(hideLoader());
        });
      }
    }
  };

  return (
    <CustomModal
      open={modOpen}
      onClose={modelOpen}
      header="Book an Appointment"
      rightModal
      modalWidth={30}
    >
      <Grid container spacing={2} className="ph20 scroll-80vh">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card
                sx={{
                  borderRadius: 1,
                  padding: 2,
                  borderTopColor: AppColors.appPrimary,
                }}
                className="CustomCard-back-appointment"
              >
                <div className="maint">
                  <div className="flex-row">
                    <Grid item xs={3}>
                      <div className="flex-center">
                        {selectPet?.petImage ? (
                          <CardMedia
                            image={selectPet?.petImage}
                            className="CustomCard-img3-bill"
                          />
                        ) : (
                          <div className="flex-center h50img">
                            <Typography className="font-bold fs30 white-color capitalize">
                              {selectPet?.petName?.[0]}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <div className="flex-row">
                        <div className="flex-start">
                          <div className="flex-column">
                            <div className="flex-row">
                              <Typography
                                variant="body1"
                                className="mb10 font-bold fs14 capitalize"
                              >
                                {selectPet?.petName}
                              </Typography>
                              <Typography
                                variant="body1"
                                className={`ml5 font-medium capitalize fs14 ${
                                  selectPet?.gender === "male"
                                    ? "card-blue-color"
                                    : "card-rose-color"
                                }`}
                              >
                                {`(${selectPet?.gender})`}
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              className="mb10 txt-regular card-gray-color fs12"
                            >
                              {selectPet?.breed}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </div>
                  <div className="flex-row iconcontainer flex-space-btwn">
                    <div className="flex-row iconcontainer">
                      <AccountCircleOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                      />
                      <Typography className="font-bold fs14 capitalize flex-center h35">
                        {selectPet?.userName}
                      </Typography>
                    </div>

                    <div className="meeting-doctor">
                      {moment(selectPet?.dob).fromNow()} ({selectPet?.dob})
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <div className="txt-mont fs14 ">
            Booking Type <span className="red-color">*</span>
          </div>
          <Select
            list={typeAppointemnt}
            value={petValues?.typeOfAppointment}
            handleChange={(e) => handleSelect(e, "typeOfAppointment")}
            name="typeOfAppointment"
            select
            helperText={petHelpers?.typeOfAppointment}
            error={petErrors?.typeOfAppointment}
            labelTop
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <div className="txt-mont fs14 ">
            Consultation mode <span className="red-color">*</span>
          </div>
          <Select
            list={
              petValues.typeOfAppointment === "Walk-in" ? typeListNew : typeList
            }
            value={
              petValues?.typeOfAppointment === "Walk-in"
                ? "Physical"
                : petValues?.consultationType
            }
            handleChange={(e) => handleSelect(e, "consultationType")}
            name="consultationType"
            select
            helperText={petHelpers?.consultationType}
            error={petErrors?.consultationType}
            labelTop
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="txt-mont fs14 ">
            Service type <span className="red-color">*</span>
          </div>
          <Select
            list={serviceType}
            value={petValues?.serviceType}
            handleChange={(e) => handleSelect(e, "serviceType")}
            select
            helperText={petHelpers?.serviceType}
            error={petErrors?.serviceType}
            labelTop
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="txt-mont fs14 ">
            Reason <span className="red-color">*</span>
          </div>
          <CustomTextField
            name="reason"
            fullWidth
            handleChange={(e) => handleSelect(e, "reason")}
            value={petValues?.reason}
            helperText={petHelpers?.reason}
            error={petErrors?.reason}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="txt-mont fs14 ">
            Vet Name <span className="red-color">*</span>
          </div>
          <Select
            list={vets}
            value={petValues?.vetName}
            handleChange={(e) => handleSelect(e, "vetName")}
            name="vetName"
            select
            helperText={petHelpers?.vetName}
            error={petErrors?.vetName}
            labelTop
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mbminus">
          <div className="flex-row">
            <div className="txt-mont fs14 mt10">
              Appointment <span className="red-color">*</span> :
            </div>
            <div className="ml5">
              <CustomCheckbox
                radio
                value={petValues?.appointmentOption}
                onChange={(e) => handleSelect(e, "appointmentOption")}
                radios={
                  petValues.typeOfAppointment !== "Phone"
                    ? [
                        { label: "Immediate", value: "Immediate" },
                        { label: "Later", value: "Later" },
                      ]
                    : [{ label: "Later", value: "Later" }]
                }
              />
            </div>
          </div>
          {petErrors?.appointmentOption && (
            <Typography color="error" className="ml5 mt5 fs14">
              Appointment is Required
            </Typography>
          )}
        </Grid>

        {petValues?.appointmentOption === "Later" && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-mont fs14">
              Date <span className="red-color">*</span>
            </div>
            <div className="mb20">
              <CustomTextField
                name="date"
                value={petValues?.date}
                labelTop
                fullWidth
                handleChange={(e) => handleSelect(e, "date")}
                mobileDateWithBatch
                markDateList={holidayDates ?? []}
                minDate={new Date()}
              />
            </div>
          </Grid>
        )}
        {petValues?.appointmentOption === "Later" && petValues?.date && (
          <div className="flex-row-wrap1">
            {activeSlotList?.length > 0 &&
              activeSlotList?.map((item, i) => {
                return (
                  <div
                    key={item?.time + i}
                    onClick={() => handleSelectTime(item)}
                    className={
                      selectTimeId === item?.slotTimeId
                        ? "bgContainerSelected cursor"
                        : "bgContainerUnselected cursor"
                    }
                  >
                    {item?.time}
                  </div>
                );
              })}
          </div>
        )}

        {selectTimeIdError && (
          <Typography color="error" className="ml5 mt5 fs14">
            Time is Requried
          </Typography>
        )}
      </Grid>
      <div className="flex1-end">
        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
          <CustomButton text="Submit" onClick={handleSubmit} />
        </Grid>
      </div>
    </CustomModal>
  );
};

export default ClinicPetBookAppointment;
