import {
  Card,
  CardMedia,
  Container,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import Table from "../../../components/CustomTable";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import TopBar from "../../../components/TopBar/TopBar";
import {
  getAllClinicPayment,
  getAllClinicPaymentOfHugeRecord,
  updatePayment,
} from "../../../redux/reducers/clinicSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import "./ClinicPayments.css";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import SortIcon from "@mui/icons-material/Sort";
import { useReactToPrint } from "react-to-print";
import payImg from "../../../assets/images/png/pay.png";
import viewImg from "../../../assets/images/png/view.png";
import CustomCard from "../../../components/CustomCard/CustomCard";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { AppColors } from "../../../util/AppColors";
import { PaymentTypeList, paymentFilters } from "../../../util/dropList";
import { getBorderColor } from "../../../util/function";
import VetAndUpcomingAppointments from "../../CommonScreens/VetAndUpcomingAppointments/VetAndUpcomingAppointments";

const tableHeaders = [
  "petName",
  "vetName",
  "balanceDue",
  "paymentStatus",
  "trDate",
  "pay",
];

const initialValues = {
  amount: "",
  paymentMode: "cash",
};

const ClinicPayments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isTableView, setTableView] = useState(true);
  const [tablePayments, setTablePayments] = useState([]);
  const [payValues, setPayValues] = useState(initialValues);
  const [searchText, setSearchText] = useState("");
  const [payModalVisible, setPayModalVisible] = useState(false);
  const [selectedType, setSelectedType] = useState("all");
  const [selectMode, setSelectMode] = useState("all");
  const [modalData, setModalData] = useState();
  const [page, setPage] = useState(1);
  const itemsPerLoad = 10;
  const hugeRecordSize = 50;
  const [cardPage, setCardPage] = useState(1);
  const cardItemsPerLoad = 12;
  const [selectedBill, setSelectedBill] = useState(null);
  const contentToPrint = useRef(null);
  const [invModVisible, setInvModVisible] = useState(false);
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const payments = useSelector((state) => state.clinic.payments);
  const hugePayments = useSelector((state) => state.clinic.hugePayments);
  const clinicDet = useSelector((state) => state?.clinic?.details);

  useEffect(() => {
    dispatch(
      getAllClinicPaymentOfHugeRecord(
        `?filter=${selectedType}&type=${selectMode}&page=1&itemSize=${hugeRecordSize}`
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      getAllClinicPayment(
        `?filter=${selectedType}&type=${selectMode}&page=1&itemSize=${
          isTableView ? itemsPerLoad : cardItemsPerLoad
        }`
      )
    );
    setPage(1);
    setCardPage(1);
  }, [selectMode, selectedType, isTableView]);

  useEffect(() => {
    const filteredPayments = filterPayments(
      searchText?.length > 0 ? hugePayments?.data : payments?.data,
      searchText
    );
    getTablePayments(filteredPayments);
  }, [payments?.data, hugePayments?.data, searchText]);

  const createModalOpen = (li) => {
    setModalData(li);
    setPayValues(initialValues);
    setPayModalVisible(!payModalVisible);
  };

  const filterPayments = (payments, searchText) => {
    if (!searchText) return payments;

    return payments?.filter((payment) => {
      const petNameMatch = payment?.petName
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      const vetNameMatch = payment?.doctorName
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      const userNameMatch = payment?.userName
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      const userEmailMatch = payment?.email
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      const userMobileMatch = payment?.mobile
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      const paymentStatusMatch = payment?.status
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      return (
        petNameMatch ||
        vetNameMatch ||
        paymentStatusMatch ||
        userNameMatch ||
        userEmailMatch ||
        userMobileMatch
      );
    });
  };

  const handleChangeValues = (name, value) => {
    setPayValues({ ...payValues, [name]: value });
  };

  const getTablePayments = (payments) => {
    dispatch(showLoader());
    const requiredPayments = payments?.map((pay, i) => {
      return {
        ...pay,
        petName: pay?.petName,
        petImage: pay?.petImage,
        balanceDue:
          pay?.balanceDue === 0
            ? "Nil"
            : "Rs " + Number(pay?.balanceDue)?.toFixed(2),
        trDate: moment(pay?.createdDate).format("DD MMM YYYY"),
        vetName: pay?.doctorName,
        paymentStatus: pay?.status === "paid" ? "Paid" : "Unpaid",
        gender: pay?.gender,
        breed: pay?.breed,
        pay: (
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {pay?.status === "unpaid" ? (
              <img
                src={payImg}
                alt=""
                className="img-hw40"
                onClick={() => {
                  setPayValues({
                    ...payValues,
                    amount: pay?.balanceDue ?? 0,
                  });
                  createModalOpen(pay);
                }}
              />
            ) : (
              <div className="img-hw40" />
            )}
            <img
              src={viewImg}
              alt=""
              className="img-hw40"
              onClick={() => {
                if (
                  pay?.appointmentStatus === "completed" ||
                  pay?.status === "paid"
                ) {
                  setSelectedBill(pay);
                  return setInvModVisible(true);
                }
                if (
                  pay?.appointmentStatus !== "completed" &&
                  pay?.status !== "paid"
                ) {
                  return navigate("/clinic-pet-details", {
                    state: {
                      bill: pay,
                      selectedTab: "billing",
                      appointment: {
                        appoinment: {
                          appoimentId: pay?.appointmentId,
                          petId: pay?.petId,
                        },
                      },
                    },
                  });
                }
              }}
            />
          </div>
        ),
        userName: pay?.userName,
      };
    });
    setTablePayments(requiredPayments);

    dispatch(hideLoader());
  };

  const handleSelectMode = async (mode) => {
    setSelectMode(mode.target.value);
  };

  const handleSelectType = async (type) => {
    setSelectedType(type);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `${clinicDet?.name ?? ""}-${
      selectedBill?.invoiceNumbers ?? ""
    }`,
    removeAfterPrint: true,
  });

  const finalPayment = () => {
    const data = {
      paymentId: modalData.paymentId,
      paymentMode: payValues?.paymentMode,
      paymentAmount: payValues?.amount,
    };

    dispatch(updatePayment(data)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(
          getAllClinicPayment(
            `?filter=${selectedType}&type=${selectMode}&page=${
              isTableView ? page : cardPage
            }&itemSize=${isTableView ? itemsPerLoad : cardItemsPerLoad}`
          )
        );
        setPayModalVisible(false);
        setPayValues(initialValues);
      }
    });
  };

  const handleInvModClose = () => {
    setSelectedBill(null);
    setInvModVisible(false);
  };

  const handleChangePage = (e, selectedPage) => {
    dispatch(
      getAllClinicPayment(
        `?filter=${selectedType}&type=${selectMode}&page=${selectedPage}&itemSize=${
          isTableView ? itemsPerLoad : cardItemsPerLoad
        }`
      )
    );
    if (isTableView) {
      return setPage(selectedPage);
    }
    setCardPage(selectedPage);
  };

  return (
    <>
      <VetAndUpcomingAppointments left={false}>
        <Grid container className="ph2">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="back-white"
          >
            <TopBar>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row">
                  <div className="w20Per mt10">
                    <div className="flex-center">
                      <Typography
                        className={`font-bold fs14 cursor day-type-box ${
                          selectedType === "today" ? "black" : "gray2"
                        }`}
                        onClick={() => handleSelectType("today")}
                      >
                        Today
                      </Typography>
                      <Typography
                        className={`font-bold fs14 cursor day-type-box ${
                          selectedType === "all" ? "black" : "gray2"
                        }`}
                        onClick={() => handleSelectType("all")}
                      >
                        All
                      </Typography>
                    </div>
                  </div>
                  <div className="top-row-cen-con w100Per textfield">
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      className="ml20 "
                    >
                      <CustomTextField
                        placeholder="Search"
                        name="name"
                        fullWidth
                        handleChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                        searchBill
                        backgroundColor={"#FAF0F0"}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={1}
                      xl={1}
                      className="ml20"
                    >
                      <div className="flex-center togglebutton">
                        <div
                          onClick={() => {
                            setTableView(!isTableView);
                          }}
                          className={`p10 cursor ${
                            !isTableView ? "selected-tab" : "un-selected-tab"
                          }`}
                        >
                          <GridViewIcon sx={{ color: AppColors.appPrimary }} />
                        </div>
                        <div
                          onClick={() => {
                            setTableView(!isTableView);
                          }}
                          className={`p10 ml10 cursor ${
                            isTableView ? "selected-tab" : "un-selected-tab"
                          }`}
                        >
                          <SortIcon sx={{ color: AppColors.appPrimary }} />
                        </div>
                      </div>
                    </Grid>

                    <div className="top-row-left-con w15Per allselect">
                      <Select
                        list={paymentFilters}
                        value={selectMode ?? "all"}
                        handleChange={(e) => handleSelectMode(e)}
                        name="vet"
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </TopBar>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mv3 back-white"
          >
            {isTableView ? (
              <Table
                columns={tableHeaders}
                datas={tablePayments}
                onClickPay={() => {}}
                page={page}
                rowsPerPage={itemsPerLoad}
                grey
                totalRecords={payments?.totalRecords}
                handleChangePage={handleChangePage}
                maxHeight={500}
              />
            ) : (
              <>
                {tablePayments?.length > 0 ? (
                  <>
                    <Container maxWidth="xl" className="scroll-70vh">
                      <CustomCard
                        list={tablePayments}
                        bill
                        handleEditBill={(bil) => {
                          const bill = bil;
                          delete bill.pay;
                          // navigate("/history", { state: { bill } });
                          navigate("/clinic-pet-details", {
                            state: {
                              bill,
                              selectedTab: "billing",
                              appointment: {
                                appoinment: {
                                  appoimentId: bill?.appointmentId,
                                  petId: bill?.petId,
                                },
                              },
                            },
                          });
                        }}
                        handleViewPayBill={(bil) => {
                          setPayValues({
                            ...payValues,
                            amount: bil?.balanceDue ?? 0,
                          });
                          createModalOpen(bil);
                        }}
                        handleViewBill={(bil) => {
                          setSelectedBill(bil);
                          setInvModVisible(true);
                        }}
                        handlePayBill={(bil) => {
                          setPayValues({
                            ...payValues,
                            amount: bil?.balanceDue ?? 0,
                          });
                          createModalOpen(bil);
                        }}
                      />
                    </Container>
                    {Math.ceil(payments?.totalRecords / cardItemsPerLoad) >
                    1 ? (
                      <div className="flex-end">
                        <Pagination
                          count={Math.ceil(
                            payments?.totalRecords / cardItemsPerLoad
                          )}
                          variant="outlined"
                          color="primary"
                          page={cardPage}
                          onChange={handleChangePage}
                        />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <div className="no-rec">No records available</div>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <CustomModal
          open={payModalVisible}
          onClose={createModalOpen}
          header="Pay"
          rightModal
          modalWidth={30}
        >
          <Card
            sx={{
              borderRadius: 1,
              padding: 2,
              borderTopColor: `${getBorderColor(modalData?.status)}`,
            }}
            className="CustomCard-back-bill-payment"
          >
            <div className="maint">
              <div className="flex-row topt">
                <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                  <div className="flex-center">
                    {modalData?.petImage ? (
                      <CardMedia
                        image={modalData?.petImage}
                        className="CustomCard-img3-bill"
                      />
                    ) : (
                      <div className="flex-center h50img">
                        <Typography className="font-bold fs30 white-color capitalize">
                          {modalData?.petName?.[0]}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <div className="flex-row">
                  <div className="flex-start">
                    <div className="flex-column">
                      <div className="flex-row">
                        <Typography
                          variant="body1"
                          className="mb10 font-bold fs14 capitalize"
                        >
                          {modalData?.petName}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={`ml5 capitalize font-medium fs14 ${
                            modalData?.gender === "male"
                              ? "card-blue-color"
                              : "card-rose-color"
                          }`}
                        >
                          {`(${modalData?.gender})`}
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        className="mb10 txt-regular card-gray-color fs12"
                      >
                        {modalData?.breed}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div />
              </div>
              <div>
                <div className="flex-row parentcontainer">
                  <div className="flex-row  iconcontainer">
                    <AccountCircleOutlinedIcon sx={{ width: 25, height: 25 }} />
                    <Typography
                      variant="body1"
                      className="  font-bold fs14 capitalize flex-center h35"
                    >
                      {modalData?.userName ?? modalData?.vetName}
                    </Typography>
                  </div>
                  <div className="meeting-doctor">
                    Meeting Dr. {modalData?.doctorName}
                  </div>
                </div>
                <div className="trdatenew">{modalData?.trDate}</div>
                <div
                  className={
                    modalData?.balanceDue === 0 ||
                    modalData?.balanceDue === "Nil"
                      ? "balancedueblue"
                      : "balanceduered"
                  }
                >
                  Balance due: {Number(modalData?.balanceDue)?.toFixed(2)}
                </div>
                <div className="datecontainer"></div>
              </div>
            </div>
            <div
              className={`conttype ${
                modalData?.appointmentType === "Physical"
                  ? "card-con-blue-back"
                  : "virtual-bg-color"
              }`}
            >
              {modalData?.appointmentType}
            </div>
          </Card>
          <Grid container spacing={2} className="ph20">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <CustomTextField
                label="Amount"
                name="amount"
                fullWidth
                handleChange={(e) =>
                  handleChangeValues("amount", e?.target?.value)
                }
                value={payValues?.amount}
                labelTop
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Select
                list={PaymentTypeList}
                value={payValues?.paymentMode}
                handleChange={(e) =>
                  handleChangeValues("paymentMode", e?.target?.value)
                }
                name="paymentMode"
                label="Payment Mode"
                select
                labelTop
              />
            </Grid>

            <div className="flex1-end mt20">
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <CustomButton text="Pay" onClick={finalPayment} />
              </Grid>
            </div>
          </Grid>
        </CustomModal>

        <CustomModal
          open={invModVisible}
          onClose={handleInvModClose}
          header=""
          modal
          modalWidth={70}
        >
          <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
            <div ref={contentToPrint} className="bg">
              <div className="flex-row-ali-cen mv20">
                <div className="w30Per">
                  {profile?.image ? (
                    <img src={profile?.image} className="h75ml30img" alt="" />
                  ) : (
                    <div className="flex-center h50img ml40">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {profile?.name?.[0]}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className="w40Per flex-center">
                  <div className="font-bold fs18 blue-color">INVOICE</div>
                </div>
                <div className="w30Per flex1-end">
                  <div className="flex-column">
                    <div className="font-bold fs12 blue-color">
                      Business Address:
                    </div>
                    <div className="header-right-text2">
                      {clinicDet?.address ?? ""}
                    </div>
                    {clinicDet?.taxId?.length > 0 ? (
                      <div className="flex-row-ali-cen">
                        <div className="font-bold fs12 blue-color">
                          TAX ID:{" "}
                        </div>
                        <div className="header-right-text2 ml3">
                          {clinicDet?.taxId ?? ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {clinicDet?.mobile?.length > 0 ? (
                      <div className="flex-row-ali-cen">
                        <div className="font-bold fs12 blue-color">
                          Contact:{" "}
                        </div>
                        <div className="header-right-text2 ml3">
                          {clinicDet?.mobile ?? ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="line"></div>
              <div className="flex1-end mt10 ph30">
                <div className="flex-column">
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Payment Status
                    </Typography>
                    <Typography
                      className={`ml10 fs16 font-bold capitalize ${
                        selectedBill?.status === "paid"
                          ? "green2"
                          : selectedBill?.status === "unpaid"
                          ? "red2"
                          : "orange-color"
                      }`}
                    >
                      {selectedBill?.status}
                    </Typography>
                  </div>
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Total
                    </Typography>
                    <Typography className="black fs16 font-bold ml10">
                      Rs {Number(selectedBill?.totalAmt)?.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="lower-container">
                <div className="box-container">
                  <div className="row-container">
                    <div>
                      <div className="colum-header">Pet Name</div>
                      <div className="colum-data">{selectedBill?.petName}</div>
                    </div>
                    <div>
                      <div className="colum-header">Parent Name</div>
                      <div className="colum-data capitalize">
                        {selectedBill?.userName}
                      </div>
                    </div>
                    <div>
                      <div className="colum-header">Invoice Number</div>
                      <div className="colum-data">
                        {selectedBill?.invoiceNumbers ?? ""}
                      </div>
                    </div>
                    <div>
                      <div className="colum-header">Invoice Date</div>
                      <div className="colum-data">
                        {moment(new Date(selectedBill?.createdDate)).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="thin-line"></div>

                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>SERVICE / ITEM NAME</th>
                        <th>QTY</th>
                        <th>PRICE</th>
                        <th>DISCOUNT (Rs)</th>
                        <th>TAX</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedBill?.paymentDetails
                        ?.filter((pd) => pd?.type === "consultation")
                        ?.map((con) => {
                          return (
                            <tr>
                              <td>
                                <div className="flex-row-ali-cen">
                                  Consultation ({con?.name}){" "}
                                  {selectedBill?.doctorName?.length > 0
                                    ? " - "
                                    : ""}
                                  <div className="font-bold fs12 black ml3">
                                    {selectedBill?.doctorName?.length > 0
                                      ? `Dr. ${selectedBill?.doctorName}`
                                      : ""}
                                  </div>
                                  {selectedBill?.registrationNo?.length > 0 ? (
                                    <div className="flex-row-ali-cen">
                                      <div className="font-regular fs12 black ml5">
                                        (
                                      </div>
                                      <div className="font-bold fs12 black ml2">
                                        RegNo:
                                      </div>
                                      <div className="font-regular fs12 black ml5 capitalize">
                                        {selectedBill?.registrationNo} )
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                              <td>1</td>
                              <td>Rs {Number(con?.price)?.toFixed(2)}</td>
                              <td>-</td>
                              <td>
                                {con?.tax > 0
                                  ? Number(con?.tax)?.toFixed(2)
                                  : "-"}
                              </td>
                              <td>Rs {con?.total}</td>
                            </tr>
                          );
                        })}

                      {selectedBill?.paymentDetails
                        ?.filter((pd) => pd?.type !== "consultation")
                        ?.map((itm, i) => (
                          <tr key={i}>
                            <td>{itm?.name}</td>
                            <td>{itm?.quantity}</td>
                            <td>Rs {itm?.price}</td>
                            <td>-</td>
                            <td>{itm?.tax > 0 ? itm?.tax : "-"}</td>
                            <td>Rs {itm?.total}</td>
                          </tr>
                        ))}

                      <tr>
                        <td colSpan="6">
                          <hr className="thin-line2" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3"></td>
                        <td>
                          <div className="txtstyle">Subtotal</div>
                        </td>
                        <td colSpan="1"></td>
                        <td>
                          <div className="txtstyle">
                            Rs{" "}
                            {Number(
                              selectedBill?.totalAmt - selectedBill?.tax ?? 0
                            )?.toFixed(2)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3"></td>
                        <td>
                          <div className="txtstyle">Tax</div>
                        </td>
                        <td colSpan="1"></td>
                        <td>
                          <div className="txtstyle">
                            {selectedBill?.tax > 0
                              ? `Rs ${Number(selectedBill?.tax)?.toFixed(2)}`
                              : "-"}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3"></td>
                        <div className="amounttxtcontainer ph5">
                          <td>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#5D9911",
                              }}
                            >
                              Amount Paid
                            </div>
                          </td>
                        </div>
                        <td colSpan="1" className="amounttxtcontainer ph5"></td>
                        <div className="amounttxtcontainer ph5">
                          <td>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#5D9911",
                              }}
                            >
                              {selectedBill?.amtPaid > 0
                                ? `Rs ${Number(selectedBill?.amtPaid)?.toFixed(
                                    2
                                  )}`
                                : "-"}
                            </div>
                          </td>
                        </div>
                      </tr>
                      <div className="mt10" />
                      <tr>
                        <td colSpan="3"></td>
                        <div className="balancecontainer ph5">
                          <td>
                            <div className="balancetext">Balance due</div>
                          </td>
                        </div>

                        <td colSpan="1" className="balancecontainer ph5"></td>

                        <div className="balancecontainer ph5">
                          <td>
                            <div className="balancetext">
                              Rs {Number(selectedBill?.balanceDue)?.toFixed(2)}
                            </div>
                          </td>
                        </div>
                      </tr>
                      <div className="mb20" />
                    </tbody>
                  </table>
                  <div className="flex-row mv20 ph10">
                    <img
                      src={require("../../../assets/images/jpg/WoofQrCode.jpeg")}
                      alt=""
                      className="img-h50"
                    />
                    <div className="blu-back wh50 flex-center">
                      <img
                        src={require("../../../assets/images/png/VetInstantLogo.png")}
                        alt=""
                        className="img-h40"
                      />
                    </div>
                    <div className="flex-center">
                      <div className="font-bold fs14 ml20">
                        Scan to download our app
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="back-white"
            >
              <div className="flex-end">
                <div className="mr20 mv20">
                  <CustomButton
                    text="Download"
                    onClick={() =>
                      handlePrint(null, () => contentToPrint.current)
                    }
                    smallBtn
                  />
                </div>
              </div>
            </Grid>
          </div>
        </CustomModal>
      </VetAndUpcomingAppointments>
    </>
  );
};

export default ClinicPayments;
