import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../actions/api";
import { hideLoader, showLoader } from "./loaderSlice";

export const getChatList = createAsyncThunk(
  "getChatList",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).get(
        `user/chatlist`
      );

      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return res?.data?.data;
      }
      if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
        return false;
      }
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      return false;
    }
  }
);

export const getChatListWithoutLoading = createAsyncThunk(
  "getChatListWithoutLoading",
  async (_, thunkAPI) => {
    try {
      const res = await api({ contentType: true, auth: true }).get(
        `user/chatlist`
      );

      if (res.status === 200) {
        return res?.data?.data;
      }
      if (res.status !== 200) {
        return false;
      }
    } catch (err) {
      return false;
    }
  }
);

export const getAllMessagsByChatId = createAsyncThunk(
  "getAllMessagsByChatId",
  async (chatId, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).get(
        `chat/${chatId}/message`
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return res?.data?.data;
      }
      if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
        return false;
      }
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      return false;
    }
  }
);

export const createNewChat = createAsyncThunk(
  "createNewChat",
  async (userId, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).post(
        `chat?userId=${userId}`
      );
      if (res?.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return res?.data?.data;
      }
      if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
        return false;
      }
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      return false;
    }
  }
);

export const uploadChatFilesByChatId = createAsyncThunk(
  "uploadChatFilesByChatId",
  async ({ chatId, form }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoader());
      const res = await api({
        contentType: true,
        auth: true,
        formData: true,
      }).post(`/chat/${chatId}/message/file`, form);
      if (res?.status === 200) {
        thunkApi.dispatch(hideLoader());
        return res?.data?.data;
      } else {
        thunkApi.dispatch(hideLoader());
      }
    } catch (err) {
      thunkApi.dispatch(hideLoader());
      console.log("error", err);
    }
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    list: [],
    messages: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChatList.fulfilled, (state, action) => {
      state.list = action.payload ?? [];
    });
    builder.addCase(getChatListWithoutLoading.fulfilled, (state, action) => {
      state.list = action.payload;
    });
    builder.addCase(getAllMessagsByChatId.fulfilled, (state, action) => {
      state.messages = action.payload;
    });
  },
});

export default chatSlice.reducer;
