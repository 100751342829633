import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SockJS from "sockjs-client/dist/sockjs";
import Stomp from "stompjs";
import "./App.css";
import {
  getAppointsmentsByClinic,
  getClinicAppointemntListWithoutLoading,
  getClinicDashboardData,
  getClinicDetails,
  getClinicInpatients,
  getClinicNotification,
  getVetAppointmentsByIdAndFilter,
} from "./redux/reducers/clinicSlice";
import { navWidths } from "./redux/reducers/loaderSlice";
import { showSnackBar } from "./redux/reducers/snackSlice";
import Router from "./routes/Router";
import { socketServer } from "./util/server";
import moment from "moment";
import {
  getPetDetailsById,
  getPetsCompliantSummary,
} from "./redux/reducers/petSlice";
import { getChatList } from "./redux/reducers/chatSlice";

const App = () => {
  const dispatch = useDispatch();
  const stompClientRef = useRef(null);
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const role = localStorage.getItem("role");
  const comObjVal = useSelector((state) => state?.mixed?.comObj);

  // useEffect(() => {
  //   dispatch(getClinicDetails());
  //   dispatch(getClinicNotification());
  //   dispatch(getChatList());
  //   dispatch(getClinicAppointemntListWithoutLoading("?type=appointment"));
  // }, []);

  //For Calling Socket
  // useEffect(() => {
  //   dispatch(navWidths(false));
  //   if (!profile?.id) {
  //     return;
  //   }
  //   let stompClient;
  //   let reconnectAttempts = 0;
  //   const connectSocket = () => {
  //    stompClient = Stomp.over(new SockJS(socketServer));
  //   stompClientRef.current = stompClient;

  //   stompClient.connect(
  //     {},
  //     () => {
  //       console.log("WebSocket connection opened");
  //       stompClient.subscribe(
  //         `/user/${profile?.id}/topic/notification`,
  //         (message) => {
  //           // console.log("Server,Server,Server,Server", message);
  //           const parsedData = JSON.parse(message?.body);
  //           dispatch(
  //             showSnackBar({
  //               title: parsedData?.title,
  //               message: parsedData?.message,
  //               type: "notification",
  //             })
  //           );
  //           // if (role === "ROLE_CLINIC") {
  //           dispatch(getClinicNotification());
  //           // }
  //         }
  //       );
  //       stompClient.subscribe(`/user/${profile?.id}/topic/dashboard`, (res) => {
  //         dispatch(getClinicAppointemntListWithoutLoading("?type=appointment"));

  //         //for calling dashboard api
  //         if (
  //           comObjVal?.home?.selectedTab === "Outpatient" ||
  //           comObjVal?.home?.selectedTab === "Virtual"
  //         ) {
  //           const reqObj = { type: comObjVal?.home?.selectedTab };
  //           if (comObjVal?.home?.selectedVet)
  //             reqObj.url = `&doctorId=${comObjVal?.home?.selectedVet}`;
  //           dispatch(getClinicDashboardData(reqObj));
  //         } else {
  //           let url = "?type=all";
  //           if (comObjVal?.home?.selectedVet) {
  //             url = `?type=doctor&doctorId=${comObjVal?.home?.selectedVet}`;
  //           }
  //           dispatch(getClinicInpatients(url));
  //         }

  //         //for calling vet calendar api
  //         if (comObjVal?.vetCal?.userId) {
  //           dispatch(
  //             getVetAppointmentsByIdAndFilter(
  //               `${comObjVal?.vetCal?.userId}?type=${
  //                 comObjVal?.vetCal?.selectedType
  //               }${
  //                 comObjVal?.vetCal?.selectedType === "date"
  //                   ? `&date=${moment(comObjVal?.vetCal?.selectedDate).format(
  //                       "YYYY-MM-DD"
  //                     )}`
  //                   : ""
  //               }`
  //             )
  //           );
  //         }

  //         //for calling clinic pet details
  //         if (
  //           comObjVal?.petDetails?.appoimentId ||
  //           comObjVal?.petDetails?.petId
  //         ) {
  //           if (comObjVal?.petDetails?.appoimentId) {
  //             dispatch(
  //               getPetsCompliantSummary(comObjVal?.petDetails?.appoimentId)
  //             );
  //           } else {
  //             dispatch(getPetDetailsById(comObjVal?.petDetails?.petId));
  //           }
  //         }

  //         //for calling appointments
  //         if (comObjVal?.appointments?.selectedVet === "all")
  //           dispatch(
  //             getAppointsmentsByClinic(
  //               `?type=${comObjVal?.appointments?.selectedType}${
  //                 comObjVal?.appointments?.selectedType === "date"
  //                   ? `&date=${moment(
  //                       comObjVal?.appointments?.selectedDate
  //                     ).format("YYYY-MM-DD")}`
  //                   : ""
  //               }`
  //             )
  //           );
  //         else {
  //           dispatch(
  //             getVetAppointmentsByIdAndFilter(
  //               `${comObjVal?.appointments?.selectedVet}?type=${
  //                 comObjVal?.appointments?.selectedType
  //               }${
  //                 comObjVal?.appointments?.selectedType === "date"
  //                   ? `&date=${moment(
  //                       comObjVal?.appointments?.selectedDate
  //                     ).format("YYYY-MM-DD")}`
  //                   : ""
  //               }`
  //             )
  //           );
  //         }
  //       });
  //     },
  //     (error) => {
  //       console.error("WebSocket connection error:", error);
  //       handleReconnect();
  //     }
  //   );
  // }

  // connectSocket();

  // const handleReconnect = () => {
  //   if (reconnectAttempts < 5) { // Limit reconnect attempts
  //     const delay = Math.min(5000 * Math.pow(2, reconnectAttempts), 30000); // Exponential backoff, max 30s
  //     setTimeout(() => {
  //       reconnectAttempts += 1;
  //       console.log(`Reconnecting attempt #${reconnectAttempts}`);
  //       connectSocket();
  //     }, delay);
  //   } else {
  //     console.error("Max reconnect attempts reached.");
  //   }
  // };

  // // const intervalId = setInterval(() => {
  // //   if (stompClient && stompClient.connected) {
  // //     stompClient.disconnect(() => {
  // //       console.log("WebSocket connection closed");
  // //       connectSocket(); // Reconnect the WebSocket
  // //     });
  // //   }
  // // }, 5 * 60 * 1000);

  //   return () => {
  //     // clearInterval(intervalId);
  //     if (stompClient && stompClient?.connected) {
  //       stompClient?.disconnect();
  //     }
  //   };
  // }, [profile?.id]);

  return <Router />;
};

export default App;
