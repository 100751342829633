// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter } from "react-router-dom";
// import theme from "./theme";
// import { ThemeProvider } from "@mui/material/styles";
// import store from "./redux/store";
// import { Provider } from "react-redux";
// // import "@fontsource/montserrat";
// import "./assets/fonts/Montserrat-Black.ttf";
// import "./assets/fonts/Montserrat-Bold.ttf";
// import "./assets/fonts/Montserrat-Medium.ttf";
// import "./assets/fonts/Montserrat-Regular.ttf";
// import "./assets/fonts/Montserrat-Italic.ttf";
// import "./assets/fonts/Montserrat-SemiBold.ttf";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <ThemeProvider theme={theme}>
//         <Provider store={store}>
//           <App />
//         </Provider>
//       </ThemeProvider>
//     </BrowserRouter>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import Snackbar from "./components/CustomSnackbar/CustomSnackbar";
import Loader from "./components/Loader";
import { ThemeProvider } from "@mui/material/styles";
import store from "./redux/store";
import { Provider } from "react-redux";
import ThemeProvide from "./theme";
// import "@fontsource/montserrat";
import "./assets/fonts/Montserrat-Black.ttf";
import "./assets/fonts/Montserrat-Bold.ttf";
import "./assets/fonts/Montserrat-Medium.ttf";
import "./assets/fonts/Montserrat-Regular.ttf";
import "./assets/fonts/Montserrat-Italic.ttf";
import "./assets/fonts/Montserrat-SemiBold.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
        <Loader />
          <ThemeProvide>
          <App />
          <Snackbar />
          </ThemeProvide>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

