import { Container, Grid, Toolbar } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import CustomTable from "../../../components/CustomTable";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import {
  getClinicAppointemntList,
  updateAppointmentStatusById,
} from "../../../redux/reducers/clinicSlice";
import { AppColors } from "../../../util/AppColors";

const tableHeaders = [
  "petName",
  "gender",
  "breed",
  "userName",
  "vetName",
  "apntmntDteAnTim",
  "approveStatus",
];

const AppointmentApprove = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSerachType] = useState("all");
  const [filteredList, setFilteredList] = useState([]);
  const appointemnts = useSelector((state) => state?.clinic?.appointmentList);
  const defaultUrl = "?type=appointment";

  useEffect(() => {
    dispatch(getClinicAppointemntList(defaultUrl));
  }, []);

  useEffect(() => {
    getTableAppointments();
  }, [appointemnts, searchValue, searchType]);

  const getTableAppointments = () => {
    const tblList =
      appointemnts?.length > 0
        ? appointemnts?.map((apt) => ({
            ...apt,
            vetName: apt?.doctorName,
            apntmntDteAnTim: `${moment(apt?.appoinmentDate)?.format(
              "DD MMM YYYY,"
            )} ${apt?.appoimentTime}`,
            approveStatus: (
              <div className="flex-row">
                <div className="flex1-end">
                  <div>
                    <CustomButton
                      text="Approve"
                      smallBtn
                      onClick={() =>
                        updateAppntmentStatusById(apt, "scheduled")
                      }
                    />
                  </div>
                  <div className="ml10">
                    <CustomButton
                      text="Decline"
                      smallBtn
                      redBtn
                      onClick={() => updateAppntmentStatusById(apt, "Canceled")}
                    />
                  </div>
                </div>
              </div>
            ),
          }))
        : [];
    let reqList = tblList;
    if (searchValue?.length > 0) {
      reqList = tblList?.filter((apt) => {
        const doctorMatch = apt?.doctorName
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase());
        const petMatch = apt?.petName
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase());
        return searchType === "vetName" ? doctorMatch : doctorMatch || petMatch;
      });
    }
    setFilteredList(reqList);
  };

  const updateAppntmentStatusById = (apnt, status) => {
    dispatch(
      updateAppointmentStatusById({ appointmentId: apnt?.appoimentId, status })
    ).then((res) => {
      if (res?.payload) dispatch(getClinicAppointemntList(defaultUrl));
    });
  };

  return (
    <>
      <Grid
        container
        // className="topBar-main"
        sx={{ padding: 2, alignItems: "center" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Container maxWidth="xl">
            <Toolbar variant="regular">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row">
                  <div className="top-row-cen-con w100Per">
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <CustomTextField
                        placeholder="Search"
                        fullWidth
                        handleChange={(e) => setSearchValue(e?.target?.value)}
                        search
                        backgroundColor={AppColors.lightPink}
                        value={searchValue}
                      />
                    </Grid>

                    <div className="top-row-right-con w15Per topBar-select">
                      <Select
                        list={[
                          { name: "All", value: "all" },
                          { name: "Vet Name", value: "vetName" },
                        ]}
                        value={searchType}
                        handleChange={(e) => setSerachType(e?.target?.value)}
                        select
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Toolbar>
          </Container>
        </Grid>
      </Grid>

      <CustomTable columns={tableHeaders} datas={filteredList} grey approve />
    </>
  );
};

export default AppointmentApprove;
