import { Grid, Typography } from "@mui/material";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPetMedicalHistory } from "../../../../redux/reducers/medicalHistorySlice";

const Overview = ({
  getPetData,
  isVideoCallEnabled,
  setVideoCallEnabled,
  setAllergyModOpen,
  appointment,
}) => {
  const dispatch = useDispatch();
  const medHisList = useSelector(
    (state) => state?.medicalHistory?.medicalHistoryByPet
  );
  const [preventiveList, setPreventiveList] = useState([]);
  const [medicationList, setMedicationList] = useState([]);
  const reqDet = useSelector((state) => state?.pet?.complaintSummary?.data);
  const petDetails = useSelector((state) => state?.pet?.petDetails);
  const apntId = appointment?.appoinment?.appoimentId;
  const appDet = reqDet?.appointment;
  const petData = apntId ? reqDet?.pet : petDetails;
  const apnt = appointment?.appoinment;

  useEffect(() => {
    if (!getPetData?.[0]?.petId && !apnt?.petId) return;
    dispatch(getPetMedicalHistory(getPetData?.[0]?.petId ?? apnt?.petId));
  }, [getPetData?.[0]?.petId || apnt?.petId]);

  useEffect(() => {
    getRequiredList();
  }, [medHisList]);

  const getRequiredList = () => {
    const prevList = [];
    const medList = [];
    medHisList?.map((ml) => {
      if (ml?.prevetive?.length > 0) {
        ml?.prevetive?.filter((pre) => {
          if (pre?.status === "Completed") {
            if (!prevList?.find((pr) => pr?.status === "Completed")) {
              prevList?.push({ ...pre, status: pre?.status });
            }
          }
          if (pre?.status === "Pending") {
            if (!prevList?.find((pr) => pr?.status === "Pending")) {
              prevList?.push({ ...pre, status: pre?.status });
            }
          }
          if (pre?.status === "Overdue") {
            if (!prevList?.find((pr) => pr?.status === "Overdue")) {
              prevList?.push({ ...pre, status: pre?.status });
            }
          }
        });
      }
      if (ml?.prescription?.length > 0) {
        ml?.prescription?.[0]?.prescriptionDetails?.filter((pd) => {
          if (medList?.length !== 3) {
            medList?.push({ ...pd, date: ml?.prescription?.[0]?.createdDate });
          }
        });
      }
    });
    setPreventiveList(prevList);
    setMedicationList(medList);
  };

  const myMeeting = async (element) => {
    const roomID = appDet?.videoCallId;
    const userID = appDet?.doctorId?.toString();
    const userName = appDet?.doctorName;
    const token = "faeb80d20908d605f3acbf67f8c25191";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      704271205,
      token,
      roomID,
      userID,
      userName
    );

    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.GroupCall,
      },
      onLeaveRoom: async () => {
        setVideoCallEnabled(false);
        localStorage.setItem(
          "videoCallPageReloaded",
          JSON.stringify({
            state: appDet,
          })
        );
        window.location.reload();
      },
      showLeavingView: false,
      showScreenSharingButton: false,
      maxUsers: 2,
      showPreJoinView: false,
      showTextChat: false,
      showRoomDetailsButton: false,
    });
  };

  return (
    <>
      {isVideoCallEnabled ? (
        <div ref={myMeeting} className="vid-cal-con" />
      ) : null}

      <Grid container className="mv3 back-white pb100">
        {apntId && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex-row-ali-cen">
              <div className="flex-row-ali-cen ovr-head-back">
                <Typography className="txt-semi-bold fs14 balck ml10">
                  {appDet?.appoinmentStatus === "completed"
                    ? "Previous Appointment:"
                    : appDet?.appoinmentStatus !== "completed" &&
                      appDet?.appoinmentStatus !== "cancelled"
                    ? "Upcoming Appointment:"
                    : ""}
                </Typography>
                <div className="flex-center">
                  <Typography
                    className="fs14 ml5 "
                    style={{ fontWeight: "650" }}
                  >
                    {moment(appDet?.appoinmentDate).format("DD MMM YYYY")}
                  </Typography>
                </div>

                <div className="flex-center">
                  <div className="ml10 card-light-blue-back card-time">
                    <Typography className="txt-regular card-blue2 fs12">
                      {appDet?.appoimentTime}
                    </Typography>
                  </div>
                </div>
                <Typography
                  className={`font-medium fs10 ml10 card-time  ${
                    appDet?.appoinmentType === "Physical"
                      ? "card-bot-blue-back"
                      : "virtual-bg"
                  }  white-color
                flex-center`}
                >
                  {appDet?.appoinmentType}
                </Typography>
              </div>
            </div>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="flex-row-ali-cen mv5">
            <div className="flex-row ovr-head-back">
              <img
                src={require("../../../../assets/images/png/allergyIcon.png")}
                alt=""
                className="img-wh20 ml10"
              />
              <Typography className="txt-semi-bold fs14 balck ml10">
                Allergies:
              </Typography>
              {(apntId || apnt?.petId) && petData?.allergies?.length > 0 ? (
                petData?.allergies?.map((al, i) => (
                  <Typography className="txt-semi-bold fs14 card-head-orange-color ml5">
                    {`${al?.allergies}${
                      i + 1 === petData?.allergies?.length ? "" : ","
                    }`}
                  </Typography>
                ))
              ) : (
                <Typography className="txt-semi-bold fs14 card-head-orange-color ml5">
                  None specified
                </Typography>
              )}
              <div className="flex1-end">
                <img
                  src={require("../../../../assets/images/png/edit-new.png")}
                  alt=""
                  className="img-wh20 ml10 cursor"
                  onClick={() => setAllergyModOpen(true)}
                />
              </div>
            </div>
          </div>
        </Grid>

        {appDet?.petId === 17 ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="font-bold mt20">
              Appointment Condition
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography className="mt10 fs14 txt-regular blue-back-con">
                Gastrointestinal Issues - Vomiting and Lethargy
              </Typography>
            </div>
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className="font-bold mt20">
            Appointment Summary
          </Typography>
        </Grid>
        {appDet?.petId === 17 ? (
          <Typography className="mt10 fs14 txt-regular">
            The pet has been experiencing vomiting after every meal for the past
            five days, with the vomit being yellow and bile-colored. The pet's
            diet consists of pumpkin, rice, and eggs, with no known recent
            changes or ingestion of unusual substances. Additionally, the pet is
            showing signs of lethargy and has not been eating, accompanied by
            noticeable weight loss. These symptoms may indicate a
            gastrointestinal issue that requires further examination by the
            veterinarian.
          </Typography>
        ) : null}
        {appDet?.appointmentSummary?.length > 0 && appDet?.petId !== 17 ? (
          <Typography className="mt10 fs14 txt-regular">
            {appDet?.appointmentSummary}
          </Typography>
        ) : appDet?.petId === 17 ? null : (
          <div className="no-rec h50">No records available</div>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className="font-bold mt20">Clinical Summary</Typography>
        </Grid>
        {appDet?.petId === 17 ? (
          <Typography className="mt10 fs14 txt-regular">
            No sufficient information is available to generate a clinical
            summary.
          </Typography>
        ) : null}
        {appDet?.clinicalSummary?.length > 0 && appDet?.petId !== 17 ? (
          <Typography className="mt10 fs14 txt-regular">
            {appDet?.clinicalSummary}
          </Typography>
        ) : appDet?.petId === 17 ? null : (
          <div className="no-rec h50">No records available</div>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt20">
          <Typography className="font-bold fs14">Preventive History</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt10">
          <div className="flex-row-ali-cen">
            {(apntId || apnt?.petId === getPetData?.[0]?.petId) &&
            preventiveList?.length > 0 ? (
              preventiveList?.map((pl, i) => (
                <>
                  <div className="w30Per">
                    <div className="flex-column">
                      <Typography className="txt-semi-bold gray7 fs12 mt10">
                        {pl?.type === "vaccination"
                          ? "Vaccination"
                          : pl?.type === "fleaTreatment"
                          ? "Flea treatment"
                          : "Deworming"}
                      </Typography>
                      <Typography className="txt-semi-bold black2 fs12 mt10">
                        {pl?.name}
                      </Typography>
                      {pl?.date ? (
                        <div className="flex-row-ali-cen mt10">
                          <div className="gray-dot2" />
                          <Typography className="txt-semi-bold fs10 ml5 orange2">
                            Date: {moment(pl?.date).format("DD MMM YYYY")}
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                    <Typography
                      className={`txt-semi-bold fs12 mt10 ph10pv3 br5 width-fit-con ${
                        pl?.status === "Completed"
                          ? "green2"
                          : pl?.status === "Pending"
                          ? "orange3"
                          : "red3"
                      } ${
                        pl?.status === "Completed"
                          ? "green-back2"
                          : "orange-back"
                      }`}
                    >
                      {pl?.status}
                    </Typography>
                  </div>

                  {preventiveList?.length !== i + 1 ? (
                    <div className="w5Per">
                      <div className="flex-center">
                        <div className="ver-bar-h30 h100" />
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            ) : (
              <div className="no-rec h50">No records available</div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt20">
          <Typography className="font-bold fs14">Medication History</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="flex-row-ali-cen">
            {(apntId || apnt?.petId === getPetData?.[0]?.petId) &&
            medicationList?.length > 0 ? (
              medicationList?.map((ml, i) => (
                <>
                  <div className="w30Per">
                    <div className="flex-column">
                      <Typography className="txt-semi-bold gray7 fs12 mt10">
                        {ml?.name ?? ""}
                      </Typography>
                      {ml?.date ? (
                        <div className="flex-row-ali-cen mt10">
                          <div className="gray-dot2" />
                          <Typography className="txt-semi-bold fs12 ml5 black2">
                            Prescribed Date:{" "}
                            {moment(ml?.date).format("DD MMM YYYY")}
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {medicationList?.length !== i + 1 ? (
                    <div className="w5Per">
                      <div className="flex-center">
                        <div className="ver-bar-h30 h50" />
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            ) : (
              <div className="no-rec h50">No records available</div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
