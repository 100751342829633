import {
  Box,
  Card,
  CardMedia,
  Container,
  FormHelperText,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../../components/CustomTextField";
import Select from "../../../../components/Select/Select";
import { getProductsByClinic } from "../../../../redux/reducers/clinicSlice";
import {
  createPrescription,
  getPetPrescriptions,
  getPetsCompliantSummary,
  updatePrescriptionById,
} from "../../../../redux/reducers/petSlice";
import { AppColors } from "../../../../util/AppColors";
import { frequencyList, frequncyCheckList } from "../../../../util/arrayList";
import { productCategoryList } from "../../../../util/dropList";
import { CardCss } from "../../../../util/object";

const tableNameExpan = {
  type: "Type",
  name: "Name",
  dosage: "Dosage",
  qty: "Qty",
  duration: "Duration",
  frequency: "Frequency",
  meal: "",
};

const tableHeaders = [
  "type",
  "name",
  "qty",
  "dosage",
  "duration",
  "frequency",
  "meal",
];
const initialPres = {
  type: "Deworming",
  name: null,
  dosage: "1",
  duration: "1",
  frequency: [],
  // frequency: "1-1-1",
  meal: "",
  price: 0,
  tax: 0,
  customFrequency: false,
};

const mealTypes = [
  { name: "", value: "" },
  { name: "After Meal", value: "afterMeal" },
  { name: "Before Meal", value: "beforeMeal" },
];

const Prescription = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { appointment } = location.state || {};
  const [prescriptionList, setPrescriptionList] = useState([
    { ...initialPres, i: 0 },
  ]);
  const [remark, setRemark] = useState("");
  const [prevModVisible, setPrevModVisible] = useState(false);
  const [isPreUploaded, setPreUploaded] = useState(false);
  const petDet = useSelector((state) => state?.pet?.complaintSummary?.data);
  const apntmnt = petDet?.appointment;
  const [viewPresValue, setViewPresValue] = useState(null);
  const prescriptions = useSelector((state) => state?.pet?.prescriptions);
  const [searchValue, setSearchValue] = useState("");
  const metHisDet = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.medicalHistory
  );
  const [page, setPage] = useState(1);
  const itemsPerLoad = 3;
  const defaultUrl = `?page=1&itemSize=${itemsPerLoad}`;
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const [tblVisible, setTblVisible] = useState(false);
  const [notValErr, setNotValErr] = useState(false);
  const products = useSelector((state) => state?.clinic?.products);
  const [selectedPrescription, setSelectedPrescrision] = useState(null);

  useEffect(() => {
    dispatch(
      getPetPrescriptions({
        petId: appointment?.appoinment?.petId,
        url: defaultUrl,
      })
    );
    dispatch(getProductsByClinic());
  }, []);

  const getListBySelectedType = (typ) => {
    const filteredList = products?.data
      ?.filter((prd) => prd?.category === typ)
      ?.map((pd) => ({
        ...pd,
        value: pd?.name,
      }));
    return filteredList;
  };

  const handleAfterUpdate = () => {
    dispatch(
      getPetPrescriptions({
        petId: appointment?.appoinment?.petId,
        url: defaultUrl,
      })
    );
    dispatch(getPetsCompliantSummary(appointment?.appoinment?.appoimentId));
    setPage(1);
    setPreUploaded(true);
    setSelectedPrescrision(null);
  };

  const handleAddAnUpdatePrescription = async () => {
    if (
      prescriptionList?.length === 0 ||
      !prescriptionList?.length ||
      prescriptionList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length > 0
    ) {
      return setNotValErr(true);
    }

    const prescription = prescriptionList?.map((pre) => {
      // const freq = pre?.frequency?.split("-");
      const reqObj = {
        type: pre?.type,
        name: pre?.name,
        does: Number(pre?.dosage ?? 0),
        duration: pre?.duration,
        meal: pre?.meal,
        price: pre?.price ?? 0,
        tax: pre?.tax ?? 0,
        quantity: Number(pre?.qty),
        subUnit: pre?.unitType,
        morning:
          pre?.frequency?.includes("morning") &&
          !pre?.frequency?.includes("Custom"),
        afternoon:
          pre?.frequency?.includes("afternoon") &&
          !pre?.frequency?.includes("Custom"),
        night:
          pre?.frequency?.includes("night") &&
          !pre?.frequency?.includes("Custom"),
        // morning: freq?.[0] === "1" ? true : false,
        // afternoon: freq?.[1] === "1" ? true : false,
        // night: freq?.[2] === "1" ? true : false,
      };
      if (
        pre?.frequency?.includes("Custom")
        // !frequencyList?.includes(pre?.frequency)
      ) {
        reqObj.frequencyDuration = pre?.frequency;
      }
      if (pre?.productId) reqObj.productId = pre?.productId;
      if (pre?.serviceId) reqObj.serviceId = pre?.serviceId;
      if (selectedPrescription && pre?.id) reqObj.detailId = pre?.id;
      return reqObj;
    });

    const data = {
      status: selectedPrescription?.status ?? "Pending",
      remark,
      prescription,
      issue: metHisDet?.[0]?.problemType ?? "",
    };

    if (selectedPrescription) {
      dispatch(
        updatePrescriptionById({
          prescrisionId: selectedPrescription?.prescrisionId,
          data,
        })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          handleAfterUpdate();
        }
      });
    } else {
      dispatch(
        createPrescription({
          appointmentId: appointment?.appoinment?.appoimentId,
          data,
        })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          handleAfterUpdate();
        }
      });
    }
  };

  const handleAddNewPres = () => {
    setPrescriptionList([
      ...prescriptionList,
      { ...initialPres, i: prescriptionList?.length },
    ]);
  };

  const handleChangePresValue = (name, value, ind, addObj) => {
    const reqList = prescriptionList?.map((pl, i) => {
      if (i === ind) {
        const reqObj = { ...pl, [name]: value };
        if (name === "frequency") {
          if (value?.includes("Custom")) {
            reqObj.frequency = "";
            reqObj.customFrequency = true;
          }
        }
        if (name === "name" && addObj) {
          reqObj.price = addObj?.price;
          reqObj.tax = addObj?.tax;
        }
        if (name === "type") reqObj.name = null;
        if (addObj?.productId) {
          reqObj.productId = addObj?.productId;
          if (addObj.unitType) reqObj.unitType = addObj.unitType;
        }
        if (addObj?.serviceId) reqObj.serviceId = addObj?.serviceId;

        return reqObj;
      }
      return pl;
    });

    setPrescriptionList(reqList);
    if (
      reqList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length > 0
    ) {
      return setNotValErr(true);
    }
    setNotValErr(false);
  };

  const handlePreModClose = () => {
    setViewPresValue(null);
    setPrevModVisible(false);
  };

  const getPresItems = (type, count) => {
    const filteredList = viewPresValue
      ? viewPresValue?.prescriptionDetails?.filter((pd) => pd?.type === type)
      : prescriptionList?.filter((pl) => pl?.type === type);
    if (count) return filteredList?.length;

    return filteredList?.length > 0 ? (
      filteredList?.map((li, i) => {
        const currPrd = products?.data?.find((prd) => prd?.name === li?.name);

        return (
          <div className="gray-back-con" key={Date.now() + li?.id}>
            <div className="flex-row flex-row-wrap">
              <div className="w85Per">
                <div className="flex-row flex-row-wrap">
                  <div className="font-bold fs12 black">{li?.name}</div>
                  <div className="flex-row flex-center ml10">
                    <div className="gray-dot" />
                    <div className="font-medium fs12 black4 ml5">Quantity:</div>
                    <div className="txt-regular fs12 black4 ml3">
                      {li?.quantity ?? li?.qty}{" "}
                      {li?.subUnit === "Unit" || li?.unitType === "Unit"
                        ? currPrd?.measurementUnit
                        : currPrd?.subMeasurementUnit}
                    </div>
                  </div>
                  <div className="flex-row flex-center ml10">
                    <div className="gray-dot" />
                    <div className="font-medium fs12 black4 ml5">Dosage:</div>
                    <div className="txt-regular fs12 black4 ml3">
                      {li?.dosage ?? li?.does}
                    </div>
                  </div>
                  <div className="flex-row flex-center ml10">
                    <div className="gray-dot" />
                    <div className="font-medium fs12 black4 ml5">Duration:</div>
                    <div className="txt-regular fs12 black4 ml3">
                      {li?.duration} days
                    </div>
                  </div>
                  {li?.meal?.length > 0 ? (
                    <div className="flex-row flex-center ml10">
                      <div className="gray-dot" />
                      <div className="txt-regular fs12 black4 ml5">
                        {li?.meal === "afterMeal"
                          ? "After Meal"
                          : li?.meal === "beforeMeal"
                          ? "Before Meal"
                          : ""}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex-center">
                <div className="blue-back-con fs12 font-medium">
                  {viewPresValue
                    ? !li?.morning && !li?.afternoon && !li?.night
                      ? li?.frequencyDuration
                      : `${li?.morning ? "1" : "0"}-${
                          li?.afternoon ? "1" : "0"
                        }-${li?.night ? "1" : "0"}`
                    : li?.frequency}
                </div>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="no-rec">No records available</div>
    );
  };

  const handleViewPres = (presc) => {
    setViewPresValue(presc);
    setPrevModVisible(true);
  };

  const handleChangePage = (e, selectedPage) => {
    dispatch(
      getPetPrescriptions({
        petId: appointment?.appoinment?.petId,
        url: `?page=${selectedPage}&itemSize=${itemsPerLoad}`,
      })
    );
    setPage(selectedPage);
  };

  const deletePresByIndex = (ind) => {
    const reqList = prescriptionList
      .filter((pre, i) => {
        return i !== ind;
      })
      ?.map((rl, i) => ({ ...rl, i }));
    setPrescriptionList(reqList);
  };

  const handleEditPrescription = (pres) => {
    setSelectedPrescrision(pres);
    setRemark(pres?.remark);
    const reqList = pres?.prescriptionDetails?.map((pd, i) => {
      const reqName = products?.data?.find(
        (prd) => prd?.category === pd?.type && prd?.name === pd?.name
      );
      const customFrequency = !pd?.morning && !pd?.afternoon && !pd?.night;
      const reqfreq = [];
      if (pd?.morning) reqfreq.push("morning");
      if (pd?.afternoon) reqfreq.push("afternoon");
      if (pd?.night) reqfreq.push("night");

      return {
        type: pd?.type,
        name: pd?.name,
        qty: pd?.quantity,
        dosage: pd?.does,
        unitType: pd?.subUnit,
        duration: pd?.duration,
        frequency:
          !pd?.morning && !pd?.afternoon && !pd?.night
            ? pd?.frequencyDuration
            : reqfreq,
        // frequency: !frequencyList?.includes(pd?.frequencyDuration)
        //   ? pd?.frequencyDuration
        //   : `${pd?.morning ? "1" : "0"}-${pd?.afternoon ? "1" : "0"}-${
        //       pd?.night ? "1" : "0"
        //     }`,
        meal: pd?.meal,
        price: reqName?.sellPrice ?? 0,
        tax: reqName?.tax ?? 0,
        i,
        id: pd?.id,
        customFrequency,
      };
    });

    setPrescriptionList(reqList);
    setTblVisible(true);
    setPreUploaded(false);
  };

  const getListDataToShow = () => {
    return productCategoryList?.map((cat, i) => {
      return getPresItems(cat?.value, true) > 0 ? (
        <div key={Date.now() + i}>
          <div className="font-bold fs14 blue-color mv10">{cat?.value}</div>
          {getPresItems(cat?.value)}
        </div>
      ) : null;
    });
  };

  return (
    <>
      <div className="scroll-80vh w100Per">
        <Grid container className="back-white">
          {!tblVisible ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {apntmnt?.prescriptionStatus !== "Y" && (
                <div className="mt20 flex-center">
                  <div className="no-rec">
                    No records yet. Create a new prescription now
                  </div>
                </div>
              )}
              <div
                className={`flex-center mb20 ${
                  apntmnt?.prescriptionStatus === "Y" && "mt30"
                }`}
              >
                <div className="w15Per">
                  <CustomButton
                    text="Create"
                    onClick={() => setTblVisible(true)}
                    smallBtn
                  />
                </div>
              </div>
            </Grid>
          ) : tblVisible || apntmnt?.prescriptionStatus !== "Y" ? (
            <Container maxWidth="xl">
              <Box className="pv20">
                <TableContainer sx={{ maxHeight: 400 }}>
                  <Table
                    sx={{ minWidth: 200, tableLayout: "fixed" }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        {tableHeaders?.map((hl, i) => (
                          <TableCell
                            key={hl + i}
                            style={{
                              backgroundColor: AppColors.tableGrey,
                              color: AppColors.white,
                              textAlign: "left",
                              width:
                                hl === "type" || hl === "meal"
                                  ? 150
                                  : hl === "name" || hl === "qty"
                                  ? 180
                                  : hl === "dosage"
                                  ? 100
                                  : hl === "duration"
                                  ? 130
                                  : hl === "frequency"
                                  ? 140
                                  : "auto",
                            }}
                            sortDirection={false}
                            className={`table-header-text ${
                              hl === "name" ? "w30Per" : "w15Per"
                            }`}
                          >
                            {tableNameExpan?.[hl]}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {prescriptionList?.length > 0 &&
                        prescriptionList?.map((pr, ind) => {
                          const reqProduct = products?.data?.find((prd) => {
                            return pr?.productId
                              ? prd?.productId === pr?.productId
                              : prd?.name === pr?.name;
                          });

                          const reqList = [
                            {
                              name: reqProduct?.measurementUnit,
                              value: "Unit",
                            },
                            {
                              name: reqProduct?.subMeasurementUnit,
                              value: "subUnit",
                            },
                          ];

                          return (
                            <TableRow
                              tabIndex={-1}
                              key={ind + pr?.name + pr?.i}
                            >
                              {tableHeaders?.map((th, index) => (
                                <TableCell
                                  key={index + th + "tr"}
                                  component="th"
                                >
                                  <div>
                                    {th === "type" ? (
                                      <Select
                                        list={productCategoryList}
                                        value={pr?.type}
                                        select
                                        mandatoryField
                                        handleChange={(e) => {
                                          handleChangePresValue(
                                            "type",
                                            e?.target?.value,
                                            ind
                                          );
                                        }}
                                        disabled={isPreUploaded}
                                      />
                                    ) : th === "name" ? (
                                      <Select
                                        list={getListBySelectedType(pr?.type)}
                                        value={pr?.name}
                                        addNewSelect
                                        mandatoryField
                                        handleChange={(e) => {
                                          handleChangePresValue(
                                            "name",
                                            e?.value ?? e,
                                            ind,
                                            {
                                              price: e?.sellPrice ?? 0,
                                              tax: e?.tax ?? 0,
                                              productId: e?.productId ?? null,
                                              serviceId: e?.serviceId ?? null,
                                              unitType: reqList?.[0]?.value,
                                            }
                                          );
                                        }}
                                        disabled={isPreUploaded}
                                      />
                                    ) : th === "qty" ? (
                                      <div className="flex-row-ali-cen">
                                        <div style={{ width: 50 }}>
                                          <CustomTextField
                                            value={pr?.qty}
                                            handleChange={(e) =>
                                              handleChangePresValue(
                                                "qty",
                                                e?.target?.value,
                                                ind
                                              )
                                            }
                                            fullWidth
                                            type="number"
                                          />
                                        </div>

                                        <div
                                          style={{
                                            width: 80,
                                            paddingLeft: "5%",
                                          }}
                                        >
                                          <Select
                                            list={reqList}
                                            value={pr?.unitType}
                                            handleChange={(e) => {
                                              handleChangePresValue(
                                                "unitType",
                                                e?.target?.value,
                                                ind
                                              );
                                            }}
                                            name="unitType"
                                            select
                                          />
                                        </div>
                                      </div>
                                    ) : th === "dosage" ? (
                                      <CustomTextField
                                        fullWidth
                                        handleChange={(e) =>
                                          handleChangePresValue(
                                            "dosage",
                                            e?.target?.value,
                                            ind
                                          )
                                        }
                                        value={pr?.dosage}
                                        endIcon
                                        disabled={isPreUploaded}
                                      />
                                    ) : th === "duration" ? (
                                      <CustomTextField
                                        fullWidth
                                        handleChange={(e) =>
                                          handleChangePresValue(
                                            "duration",
                                            e?.target?.value,
                                            ind
                                          )
                                        }
                                        value={pr?.duration}
                                        endIcon
                                        inputIcon="days"
                                        disabled={isPreUploaded}
                                      />
                                    ) : th === "frequency" ? (
                                      pr?.customFrequency ? (
                                        <CustomTextField
                                          fullWidth
                                          handleChange={(e) =>
                                            handleChangePresValue(
                                              "frequency",
                                              e?.target?.value,
                                              ind
                                            )
                                          }
                                          value={pr?.frequency}
                                          disabled={isPreUploaded}
                                        />
                                      ) : (
                                        <Select
                                          list={frequncyCheckList}
                                          value={pr?.frequency ?? []}
                                          handleChange={(e) => {
                                            handleChangePresValue(
                                              "frequency",
                                              e?.target?.value,
                                              ind
                                            );
                                          }}
                                          multiSelectTagCheck
                                        />
                                      )
                                    ) : (
                                      // <Select
                                      //   list={frequencyList}
                                      //   value={pr?.frequency}
                                      //   addNewSelect
                                      //   plusBtn
                                      //   handleChange={(e) => {
                                      //     handleChangePresValue(
                                      //       "frequency",
                                      //       e?.value ?? e,
                                      //       ind
                                      //     );
                                      //   }}
                                      //   disabled={isPreUploaded}
                                      // />
                                      <div className="flex-row flex-center">
                                        <Select
                                          list={mealTypes}
                                          value={pr?.meal}
                                          select
                                          handleChange={(e) => {
                                            handleChangePresValue(
                                              "meal",
                                              e?.target?.value,
                                              ind
                                            );
                                          }}
                                          disabled={isPreUploaded}
                                        />
                                        {!isPreUploaded &&
                                        !selectedPrescription ? (
                                          <img
                                            src={require("../../../../assets/images/png/delete.png")}
                                            alt=""
                                            className="imghw30 ml10 cursor"
                                            onClick={() =>
                                              deletePresByIndex(ind)
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {notValErr ? (
                <div className="flex-center">
                  <FormHelperText error>
                    {prescriptionList?.length === 0 || !prescriptionList?.length
                      ? "Please add at least 1 record"
                      : prescriptionList?.some(
                          (pl) =>
                            pl?.name === "" ||
                            pl?.name === null ||
                            pl?.name === undefined
                        )
                      ? "Please add Record"
                      : ""}
                  </FormHelperText>
                </div>
              ) : null}

              {!isPreUploaded && !selectedPrescription ? (
                <div className="flex-end">
                  <div className="w15Per">
                    <CustomButton
                      text="Add New"
                      smallBtn
                      onClick={handleAddNewPres}
                    />
                  </div>
                </div>
              ) : null}

              <div className="mv20">
                <CustomTextField
                  fullWidth
                  multiline
                  placeholder="Advice"
                  rows={3}
                  handleChange={(e) => setRemark(e?.target?.value)}
                  value={remark}
                  disabled={isPreUploaded}
                />
              </div>
              <div className="flex-end">
                <div className="w15Per">
                  <CustomButton
                    text="Preview"
                    smallBtn
                    onClick={() => setPrevModVisible(true)}
                  />
                </div>
                {!isPreUploaded ? (
                  <div className="w15Per ml15">
                    <CustomButton
                      text={selectedPrescription ? "Update" : "Submit"}
                      smallBtn
                      onClick={handleAddAnUpdatePrescription}
                    />
                  </div>
                ) : null}
              </div>
            </Container>
          ) : null}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="ph20">
            <div className="box-hor-split mv20" />
            <div className="font-bold fs14 mv20 blue-color">
              Prescription History
            </div>
            <div className="flex-center">
              <div className="w75Per">
                <CustomTextField
                  search
                  placeholder="Search"
                  fullWidth
                  backgroundColor={AppColors.lightPink}
                  value={searchValue}
                  handleChange={(e) => setSearchValue(e?.target?.value)}
                />
              </div>
            </div>
            <div className="pb100 w100Per">
              {prescriptions?.data?.filter(
                (pr) =>
                  pr?.doctorName
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  pr?.issue?.toLowerCase()?.includes(searchValue.toLowerCase())
              )?.length > 0 ? (
                prescriptions?.data
                  ?.filter(
                    (pr) =>
                      pr?.doctorName
                        ?.toLowerCase()
                        ?.includes(searchValue.toLowerCase()) ||
                      pr?.issue
                        ?.toLowerCase()
                        ?.includes(searchValue.toLowerCase())
                  )
                  ?.map((pres, ind) => (
                    <div
                      className="flex-row-ali-cen mv10"
                      key={Date.now() + ind + "pres"}
                    >
                      <div className="w20Per">
                        <div className="back-img">
                          <div className="flex-center h100">
                            <div className="flex-column flex-center">
                              <Typography className="black2 fs10 font-bold">
                                {moment(new Date(pres?.createdDate)).format(
                                  "DD MMM"
                                )}
                              </Typography>
                              <Typography className="black2 fs10 font-bold mt5">
                                {moment(new Date(pres?.createdDate)).format(
                                  "YYYY"
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w60Per">
                        <Card sx={CardCss} className="inner-cards h110">
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="mt10"
                            >
                              <div className="card-top-color card-top-blue-color" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className="ph10">
                                <div className="flex-row">
                                  <div className="w90Per">
                                    <div className="flex-start">
                                      <div className="flex-column w10Per">
                                        <div className="h50">
                                          {apntmnt?.doctorImage ? (
                                            <CardMedia
                                              image={apntmnt?.doctorImage}
                                              className="img-h40"
                                            />
                                          ) : (
                                            <div className="flex-center img-hw40 back-gray2">
                                              <Typography className="font-bold fs30 white-color capitalize">
                                                {pres?.doctorName?.[0]}
                                              </Typography>
                                            </div>
                                          )}
                                        </div>
                                        <div className="card-light-blue-back card-time flex-center">
                                          <Typography className="txt-regular card-blue2 fs12">
                                            {pres?.appoimentTime ??
                                              apntmnt?.appoimentTime}
                                          </Typography>
                                        </div>
                                      </div>
                                      <div className="flex-column ml15 jus-con-spa-bet">
                                        <div className="h50">
                                          <Typography className="font-bold fs14 black capitalize">
                                            Dr. {pres?.doctorName}
                                          </Typography>
                                          {/* <Typography className="gray7 fs14 font-medium mt5 capitalize">
                                            {pres?.issue}
                                          </Typography> */}
                                        </div>
                                        <div className="card-con-blue-back card-time flex-center w100Px">
                                          <Typography className="txt-regular white-color fs12">
                                            {pres?.appoinmentType}
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w10Per">
                                    <div className="flex-center h90">
                                      <img
                                        src={require("../../../../assets/images/png/edit2.png")}
                                        alt=""
                                        className="img-wh25 cursor"
                                        onClick={() =>
                                          handleEditPrescription(pres)
                                        }
                                      />

                                      <img
                                        src={require("../../../../assets/images/png/view.png")}
                                        alt=""
                                        className="img-hw40 ml10 cursor"
                                        onClick={() => handleViewPres(pres)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="no-rec">No records available</div>
              )}
              {prescriptions?.data?.filter(
                (pr) =>
                  pr?.doctorName
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  pr?.issue?.toLowerCase()?.includes(searchValue.toLowerCase())
              )?.length > 0 ? (
                Math.ceil(prescriptions?.totalRecords / itemsPerLoad) > 1 ? (
                  <div className="flex-end">
                    <Pagination
                      count={Math.ceil(
                        prescriptions?.totalRecords / itemsPerLoad
                      )}
                      variant="outlined"
                      color="primary"
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                ) : null
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
      <CustomModal
        open={prevModVisible}
        onClose={handlePreModClose}
        header=""
        modal
        modalWidth={50}
      >
        <div className="scroll-80vh">
          <div className="p5">
            <div className="blue-box-examD">
              <div className="flex-row">
                <div className="w30Per">
                  <div className="flex-column">
                    <div className="flex-row mt10">
                      <div className="font-bold fs14 blue-color">Pet Name:</div>
                      <div className="font-bold fs14 black capitalize ml5">
                        {apntmnt?.petName}
                      </div>
                    </div>
                    <div className="flex-row mv5">
                      <div className="font-bold fs14 blue-color">Breed:</div>
                      <div className="txt-regular fs14 black capitalize ml5">
                        {apntmnt?.breed}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w45Per">
                  <div className="flex-column">
                    <div className="flex-row mt10">
                      <div className="font-bold fs14 blue-color">Vet Name:</div>
                      <div className="font-bold fs14 black ml5 capitalize">
                        {`Dr. ${
                          viewPresValue?.doctorName ?? apntmnt?.doctorName ?? ""
                        }`}
                      </div>
                      {viewPresValue?.registrationNo?.length > 0 ||
                      apntmnt?.registrationNo?.length > 0 ? (
                        <div className="flex-row-ali-cen">
                          <div className="font-regular fs14 black ml5">(</div>
                          <div className="font-bold fs14 blue-color ml2">
                            RegNo:
                          </div>
                          <div className="font-regular fs14 black ml5 capitalize">
                            {viewPresValue?.registrationNo ??
                              apntmnt?.registrationNo}{" "}
                            )
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex-row mv5">
                      <div className="font-bold fs14 blue-color">Date:</div>
                      <div className="txt-regular fs14 black ml5">
                        {viewPresValue
                          ? moment(viewPresValue?.createdDate).format(
                              "DD MMM YYYY"
                            )
                          : moment().format("DD MMM YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w25Per">
                  <div className="flex-end">
                    <img
                      alt=""
                      src={
                        profile?.image ??
                        require("../../../../assets/images/png/applologo.png")
                      }
                      className="img-h50"
                    />
                  </div>
                </div>
              </div>
              <div className="box-hor-split mv10" />
              {getListDataToShow()}
              <div className="font-bold fs14 blue-color mv10">Advice</div>
              <CustomTextField
                fullWidth
                multiline
                placeholder="Advice"
                rows={3}
                value={viewPresValue ? viewPresValue?.remark : remark}
                disabled
              />
              <div className="flex-row mv20">
                <img
                  src={require("../../../../assets/images/jpg/WoofQrCode.jpeg")}
                  alt=""
                  className="img-h50"
                />
                <div className="blu-back wh50 flex-center">
                  <img
                    src={require("../../../../assets/images/png/VetInstantLogo.png")}
                    alt=""
                    className="img-h40"
                  />
                </div>
                <div className="flex-center">
                  <div className="font-bold fs14 ml20">
                    Scan to download our app
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Prescription;
