import ForumIcon from "@mui/icons-material/Forum";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  AppBar,
  Avatar,
  Box,
  Card,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/CustomModal/CustomModal";
import {
  getAppointsmentsByClinic,
  getClinicAppointemntListWithoutLoading,
  getClinicDashboardData,
  getClinicDetails,
  getClinicInpatients,
  getClinicNotification,
  getVetAppointmentsByIdAndFilter,
} from "../../redux/reducers/clinicSlice";
import { markNotificationsReadByIds } from "../../redux/reducers/notificationSlice";
import { AppColors } from "../../util/AppColors";
import { baseURL, socketServer } from "../../util/server";
import { navAdminConfig, navClinicConfig, navVetConfig } from "../nav/config";
import "./Header.css";
import { getChatList } from "../../redux/reducers/chatSlice";
import { logout } from "../../redux/reducers/authSlice";
import { showSnackBar } from "../../redux/reducers/snackSlice";
import {
  getPetDetailsById,
  getPetsCompliantSummary,
} from "../../redux/reducers/petSlice";
import { navWidths } from "../../redux/reducers/loaderSlice";
import SockJS from "sockjs-client/dist/sockjs";
import Stomp from "stompjs";

const Header = ({ name }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stompClientRef = useRef(null);
  const role = localStorage.getItem("role");
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const [selectedTab, setSelectedTab] = useState(
    (role === "ROLE_ADMIN"
      ? navAdminConfig
      : role === "ROLE_CLINIC"
      ? navClinicConfig
      : navVetConfig)?.[0]?.path
  );
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [notModVsble, setNotModVsble] = useState(false);
  const [qrVsble, setQrVsble] = useState(false);
  const appointemnts = useSelector((state) => state?.clinic?.appointmentList);
  const notifications = useSelector((state) => state?.clinic?.notifications);
  const chatList = useSelector((state) => state?.chat?.list);
  const comObjVal = useSelector((state) => state?.mixed?.comObj);

  useEffect(() => {
    dispatch(getChatList());
    dispatch(getClinicDetails());
    dispatch(getClinicNotification());
    dispatch(getClinicAppointemntListWithoutLoading("?type=appointment"));
  }, []);

  useEffect(() => {
    dispatch(navWidths(false));
    if (!profile?.id) {
      return;
    }
    let stompClient;
    let reconnectAttempts = 0;
    const connectSocket = () => {
      stompClient = Stomp.over(new SockJS(socketServer));
      stompClientRef.current = stompClient;

      stompClient.connect(
        {},
        () => {
          console.log("WebSocket connection opened");
          stompClient.subscribe(
            `/user/${profile?.id}/topic/notification`,
            (message) => {
              // console.log("Server,Server,Server,Server", message);
              const parsedData = JSON.parse(message?.body);
              dispatch(
                showSnackBar({
                  title: parsedData?.title,
                  message: parsedData?.message,
                  type: "notification",
                })
              );
              // if (role === "ROLE_CLINIC") {
              dispatch(getClinicNotification());
              // }
            }
          );
          stompClient.subscribe(
            `/user/${profile?.id}/topic/dashboard`,
            (res) => {
              dispatch(
                getClinicAppointemntListWithoutLoading("?type=appointment")
              );

              //for calling dashboard api
              if (
                comObjVal?.home?.selectedTab === "Outpatient" ||
                comObjVal?.home?.selectedTab === "Virtual"
              ) {
                const reqObj = { type: comObjVal?.home?.selectedTab };
                if (comObjVal?.home?.selectedVet)
                  reqObj.url = `&doctorId=${comObjVal?.home?.selectedVet}`;
                dispatch(getClinicDashboardData(reqObj));
              } else {
                let url = "?type=all";
                if (comObjVal?.home?.selectedVet) {
                  url = `?type=doctor&doctorId=${comObjVal?.home?.selectedVet}`;
                }
                dispatch(getClinicInpatients(url));
              }

              //for calling vet calendar api
              if (comObjVal?.vetCal?.userId) {
                dispatch(
                  getVetAppointmentsByIdAndFilter(
                    `${comObjVal?.vetCal?.userId}?type=${
                      comObjVal?.vetCal?.selectedType
                    }${
                      comObjVal?.vetCal?.selectedType === "date"
                        ? `&date=${moment(
                            comObjVal?.vetCal?.selectedDate
                          ).format("YYYY-MM-DD")}`
                        : ""
                    }`
                  )
                );
              }

              //for calling clinic pet details
              if (
                comObjVal?.petDetails?.appoimentId ||
                comObjVal?.petDetails?.petId
              ) {
                if (comObjVal?.petDetails?.appoimentId) {
                  dispatch(
                    getPetsCompliantSummary(comObjVal?.petDetails?.appoimentId)
                  );
                } else {
                  dispatch(getPetDetailsById(comObjVal?.petDetails?.petId));
                }
              }

              //for calling appointments
              if (comObjVal?.appointments?.selectedVet === "all")
                dispatch(
                  getAppointsmentsByClinic(
                    `?type=${comObjVal?.appointments?.selectedType}${
                      comObjVal?.appointments?.selectedType === "date"
                        ? `&date=${moment(
                            comObjVal?.appointments?.selectedDate
                          ).format("YYYY-MM-DD")}`
                        : ""
                    }`
                  )
                );
              else {
                dispatch(
                  getVetAppointmentsByIdAndFilter(
                    `${comObjVal?.appointments?.selectedVet}?type=${
                      comObjVal?.appointments?.selectedType
                    }${
                      comObjVal?.appointments?.selectedType === "date"
                        ? `&date=${moment(
                            comObjVal?.appointments?.selectedDate
                          ).format("YYYY-MM-DD")}`
                        : ""
                    }`
                  )
                );
              }
            }
          );
        },
        (error) => {
          console.error("WebSocket connection error:", error);
          handleReconnect();
        }
      );
    };

    connectSocket();

    const handleReconnect = () => {
      if (reconnectAttempts < 5) {
        // Limit reconnect attempts
        const delay = Math.min(5000 * Math.pow(2, reconnectAttempts), 30000); // Exponential backoff, max 30s
        setTimeout(() => {
          reconnectAttempts += 1;
          console.log(`Reconnecting attempt #${reconnectAttempts}`);
          connectSocket();
        }, delay);
      } else {
        console.error("Max reconnect attempts reached.");
      }
    };

    // const intervalId = setInterval(() => {
    //   if (stompClient && stompClient.connected) {
    //     stompClient.disconnect(() => {
    //       console.log("WebSocket connection closed");
    //       connectSocket(); // Reconnect the WebSocket
    //     });
    //   }
    // }, 5 * 60 * 1000);

    return () => {
      // clearInterval(intervalId);
      if (stompClient && stompClient?.connected) {
        stompClient?.disconnect();
      }
    };
  }, [profile?.id]);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("hospitalId");
    localStorage.removeItem("fcmToken");

    navigate("/login");
    setAnchorElUser(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoClick = () => {
    setSelectedTab("/dashboard");
    navigate("/dashboard");
  };

  const handleNotfClick = () => {
    setNotModVsble(!notModVsble);
  };

  const updateNotificationReadStatus = () => {
    const reqIds = notifications
      ?.filter((not) => !not?.view)
      ?.map((not) => {
        return not?.notificationId;
      });

    if (reqIds?.length > 0) {
      dispatch(markNotificationsReadByIds(reqIds?.toString())).then((res) => {
        if (res?.payload) dispatch(getClinicNotification());
      });
    }
  };

  const getUnReadChatCount = () => {
    if (role === "ROLE_CLINIC") {
      return chatList?.length > 0
        ? chatList?.reduce((acc, obj) => {
            const unReadMsgCount = obj?.reqUserId === obj?.reqUserCount;
            return acc + unReadMsgCount;
          }, 0)
        : 0;
    } else if (role === "ROLE_DOCTOR") {
      return chatList?.length > 0
        ? chatList?.reduce((acc, obj) => {
            const unReadMsgCount = obj?.userId === obj?.userCount;
            return acc + unReadMsgCount;
          }, 0)
        : 0;
    }

    // return chatList?.reduce((acc, obj) => {
    //   const unReadMsgCount =
    //     obj?.reqUserId === profile?.id ? obj?.reqUserCount : obj?.userCount;
    //   return acc + unReadMsgCount;
    // }, 0);
  };

  const handleShowQR = () => {
    setAnchorElUser(null);
    setQrVsble(true);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar sx={{ minHeight: "auto" }} className="topBar-minh">
          <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleLogoClick}
              sx={{ mr: 2 }}
            >
              <img
                src={require("../../assets/images/png/VetInstantLogo.png")}
                alt="../../assets/images/png/VetInstantLogo.png"
                style={{ objectFit: "contain", height: 30 }}
              />
            </IconButton>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
              className="nav-flex-end w100Per"
            >
              {(role === "ROLE_ADMIN"
                ? navAdminConfig
                : role === "ROLE_CLINIC"
                ? navClinicConfig
                : navVetConfig
              ).map((nav) => (
                <Tooltip
                  title={nav.title}
                  style={{ textTransform: "capitalize" }}
                >
                  <IconButton
                    key={nav.path}
                    onClick={() => {
                      setSelectedTab(nav.path);
                      navigate(nav.path);
                    }}
                    className={
                      selectedTab === nav.path
                        ? "tab-selected"
                        : "tab-un-selected"
                    }
                    sx={{
                      display: "block",
                      ml: 1,
                      mr: 1,
                      "& svg": {
                        transition: "fill 0.3s ease",
                        width: 20,
                        height: 20,
                        fill: AppColors.white, // Default fill color
                      },
                      "&:hover svg": {
                        fill: AppColors.appPrimary, // Hover color
                        width: 20,
                        height: 20,
                      },
                      ...(selectedTab === nav.path && {
                        "& svg": {
                          fill: AppColors.appPrimary, // Selected color
                          width: 20,
                          height: 20,
                        },
                      }),
                    }}
                  >
                    {nav?.image ? (
                      <div className="flex-center">
                        <img
                          src={nav?.image}
                          style={{
                            height: 20,
                            width: 20,
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    ) : (
                      nav.icon
                    )}
                    {nav?.title === "Appointments" &&
                      nav?.path === "/appointments" &&
                      appointemnts?.length > 0 && (
                        <div className="red-dot top-bar-dot-pos" />
                      )}
                  </IconButton>
                </Tooltip>
              ))}
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
              className="flex1-end"
            >
              <Tooltip title="Chat" style={{ textTransform: "capitalize" }}>
                <IconButton
                  key="/chat"
                  onClick={() => {
                    setSelectedTab("/chat");
                    navigate("/chat");
                  }}
                  className={
                    selectedTab === "/chat" ? "tab-selected" : "tab-un-selected"
                  }
                  sx={{
                    display: "block",
                    ml: 1,
                    mr: 1,
                    mt: 1,
                    "& svg": {
                      transition: "fill 0.3s ease",
                      width: 20,
                      height: 20,
                      fill: AppColors.white, // Default fill color
                    },
                    "&:hover svg": {
                      fill: AppColors.appPrimary, // Hover color
                      width: 20,
                      height: 20,
                    },
                    ...(selectedTab === "/chat" && {
                      "& svg": {
                        fill: AppColors.appPrimary, // Selected color
                        width: 20,
                        height: 20,
                      },
                    }),
                  }}
                >
                  <ForumIcon />
                  {getUnReadChatCount() > 0 && (
                    <div className="not-count not-count-pos">
                      <Typography className="white-color header fs8">
                        {getUnReadChatCount()}
                      </Typography>
                    </div>
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title="Notifications">
                <IconButton
                  onClick={handleNotfClick}
                  sx={{
                    pr: 3,
                    "& svg": {
                      transition: "fill 0.3s ease",
                      width: 20,
                      height: 20,
                      fill: AppColors.white, // Default fill color
                    },
                    "&:hover svg": {
                      fill: AppColors.btnWhite, // Hover color
                      width: 20,
                      height: 20,
                    },
                  }}
                >
                  <NotificationsNoneOutlinedIcon />
                  {notifications?.filter((not) => !not?.view)?.length > 0 && (
                    <div className="not-count not-count-pos">
                      <Typography className="white-color header fs8">
                        {notifications?.filter((not) => !not?.view)?.length}
                      </Typography>
                    </div>
                  )}
                </IconButton>
              </Tooltip>

              <div className="flex-column">
                <Typography
                  variant="subtitle1"
                  noWrap
                  component="a"
                  sx={{
                    mr: 2,
                    fontSize: "12px",
                    display: { xs: "none", md: "flex" },
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  {profile?.name}
                </Typography>
                <Typography
                  variant="caption"
                  noWrap
                  component="b"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  {role === "ROLE_CLINIC"
                    ? "Clinic"
                    : role === "ROLE_DOCTOR"
                    ? "Doctor"
                    : "Admin"}
                </Typography>
              </div>
              <Tooltip title="My Profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={profile?.image} src={profile?.image} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    if (role === "ROLE_DOCTOR") {
                      navigate("/vet-calendar");
                    } else {
                      navigate("/branch-management");
                    }
                    setAnchorElUser(false);
                  }}
                >
                  <Typography textAlign="center" className="txt-regular">
                    Profile Settings
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleShowQR}>
                  <Typography textAlign="center" className="txt-regular">
                    Show My QR
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center" className="txt-regular">
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <CustomModal open={qrVsble} onClose={() => setQrVsble(false)}>
        <div className="flex-center h100Per">
          <img
            src={`${baseURL}/generateQRCode?text=${
              role === "ROLE_CLINIC" ? profile?.id : profile?.createdBy
            }`}
            alt=""
            className="img-wh300"
          />
        </div>
      </CustomModal>
      {notModVsble && (
        <Modal
          open={notModVsble}
          onClose={() => setNotModVsble(false)}
          className="not-con-pos"
        >
          <Card className="br5 ph20pv15">
            <div className="flex-row-between-align-center">
              <Typography className="yellow-color fs16 font-bold">
                New for you
              </Typography>
              <Typography
                className="txt-regular fs14 black9 cursor"
                onClick={updateNotificationReadStatus}
              >
                Mark as read
              </Typography>
            </div>
            {notifications?.length > 0 ? (
              <div className="scroll-max-50vh">
                {notifications?.map((not, i) => (
                  <div
                    className="flex-row-between-align-center mv10"
                    key={not?.notificationId + i}
                  >
                    <div className="flex-row">
                      <div className="not-icon">
                        <img
                          className="img-wh15"
                          alt=""
                          src={require("../../assets/images/png/notIcon.png")}
                        />
                      </div>
                      <div className="mlMin15">
                        <img
                          className="img-wh35 br6"
                          alt=""
                          src="https://picsum.photos/200"
                        />
                      </div>
                      <div className="ml10 flex-column">
                        <Typography className="txt-regular fs14 black10 flex-row-wrap">
                          {not?.content}
                        </Typography>
                        <Typography className="txt-regular fs12 black9">
                          · {moment(not?.createdDate).format("MMM DD")} ·{" "}
                          {moment(not?.createdDate).format("HH:mm")}
                        </Typography>
                      </div>
                    </div>
                    {!not?.view && (
                      <div className="flex-center">
                        <div className="red-dot2" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-rec h100">No records available</div>
            )}
          </Card>
        </Modal>
      )}
    </AppBar>
  );
};

Header.propTypes = {
  onOpenNav: PropTypes.func,
  search: PropTypes.bool,
  headerText: PropTypes.string,
  headerTextVisible: PropTypes.bool,
  isSwichProfileVisible: PropTypes.bool,
};

Header.defaultProps = {
  onOpenNav: () => {},
  search: false,
  headerText: "",
  headerTextVisible: false,
  isSwichProfileVisible: false,
};

export default Header;
